import axios from "axios";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBIcon,
  MDBSelect,
  MDBCardImage,
  MDBFooter,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBSpinner,
  MDBSwitch,
  MDBInput,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { TaskHeading } from "../../types/appointment";
import { Currency, SaleType, product } from "../../types/products";
//Import logos
import so_logo from "../../assets/so_logo.png";
import dinero_logo from "../../assets/dinero_logo.png";
import salary_logo from "../../assets/salary_logo.jpeg";
import {
  getSettings,
  saveSettings,
  validateSettings,
} from "../../utils/settings";
import { settings } from "../../types/system";
import { unitTypeArray } from "../../utils/units";
import { Associate } from "../../types/associate";
import { Employee } from "../../types/employee";
import AssociateTableRowHeader from "../elements/associateTable/AssociateTableRowHeader";
import AssociateTableRow from "../elements/associateTable/AssociateTableRow";
/****
 *
 *
 * Tab that will hold the settings for the app, stored in localstorage.
 * These are the necessary settings:
 * - Heading in SuperOffice for invoicable appointments, as dropdown
 * - Heading for invoicable driving, as dropdown
 * - Product for invoicable appointments, as autocomplete dropdown
 * - Product for invoicable driving, as autocomplete dropdown
 * - Default unit type for invoicable appointments, as dropdown, with these options: hours, parts, km, day, week, month, kilogram, cubicMetre, set, litre, box, case, carton, metre, package, shipment, squareMetre, session, tonne. (With optimal english translation)
 * - Sale type for created sales in SuperOffice, as dropdown
 *
 */

interface SettingsTabProps {
  dineroOrg: number;
  activeTab: string;
  salaryOrg: string;
  changeTab: (tab: string) => void;
}

const SettingsTab = (props: SettingsTabProps) => {
  const settings: settings | null = getSettings();
  const [confirmModal, setConfirmModal] = useState(false);
  const toggleShow = () => setConfirmModal(!confirmModal);
  //State for the headings in SuperOffice
  const [headingData, setHeadingData] = useState<TaskHeading[]>([]);
  //State for the products in Dinero
  const [productData, setProductData] = useState<product[]>([]);
  //State for the sale types in SuperOffice
  const [saleTypeData, setSaleTypeData] = useState<SaleType[]>([]);
  //State for the currency types in superoffice
  const [currencyData, setCurrencyData] = useState<Currency[]>([]);

  //State for setting the selected product for invoicable appointments
  const [selectedProduct1, setSelectedProduct1] = useState<{
    guid: string;
    accountNumber: number;
  }>({ guid: "", accountNumber: 0 });
  //State for setting the selected product for invoicable driving
  const [selectedProduct2, setSelectedProduct2] = useState<{
    guid: string;
    accountNumber: number;
  }>({ guid: "", accountNumber: 0 });
  //State for setting the selected heading for invoicable appointments
  const [selectedHeading1, setSelectedHeading1] = useState<number>(0);
  //State for setting the selected heading for invoicable driving
  const [selectedHeading2, setSelectedHeading2] = useState<number>(0);
  //State for setting the selected heading for non-invoicable driving
  const [selectedHeading3, setSelectedHeading3] = useState<number>(0);
  //state for setting the selected unit type for invoicable appointments
  const [selectedUnitType1, setSelectedUnitType1] = useState<string>("");
  const [selectedUnitType2, setSelectedUnitType2] = useState<string>("");
  //state for setting the selected unit type for invoicable driving
  const [selectedSaleType, setSelectedSaleType] = useState<number>(0);
  //ref for pricePerUnitRef
  const pricePerUnitRef = React.createRef<HTMLInputElement>();
  //ref for bonusTextRef
  const bonusTextRef = React.createRef<HTMLInputElement>();

  //state for setting the selected currency type
  const [selectedCurrency, setSelectedCurrency] = useState<{
    iso_code: string;
    currencyId: number;
  }>({ iso_code: "", currencyId: 0 });

  //state for setting the selected bonus type
  const [selectedBonusType, setSelectedBonusType] = useState<string>("");
  //State for loading
  //const [loading, setLoading] = useState(false);

  const fetchHeadings = async () => {
    console.log("fetching headings");
    try {
      //First fetch all heading items from superoffice, by calling api/superoffice/taskheadings
      axios
        .get<TaskHeading[]>(
          `${process.env.REACT_APP_API_URL}/api/superoffice/taskheadings`,
          { withCredentials: true }
        )
        .then((res) => {
          setHeadingData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const fetchProducts = async () => {
    console.log("fetching products");
    try {
      //First fetch all heading items from superoffice, by calling api/superoffice/taskheadings
      axios
        .get<product[]>(
          `${process.env.REACT_APP_API_URL}/api/dinero/${props.dineroOrg}/products`,
          { withCredentials: true }
        )
        .then((res) => {
          setProductData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //Fetch superoffice sale types
  const fetchSaleTypes = async () => {
    try {
      //First fetch all heading items from superoffice, by calling api/superoffice/taskheadings
      axios
        .get<SaleType[]>(
          `${process.env.REACT_APP_API_URL}/api/superoffice/saletypes`,
          { withCredentials: true }
        )
        .then((res) => {
          setSaleTypeData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //Fetch currency types
  const fetchCurrencyTypes = async () => {
    try {
      //First fetch all heading items from superoffice, by calling api/superoffice/taskheadings
      axios
        .get<Currency[]>(
          `${process.env.REACT_APP_API_URL}/api/superoffice/currencies`,
          { withCredentials: true }
        )
        .then((res) => {
          setCurrencyData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //State for showing deleted associates
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  //State for loading
  const [loading, setLoading] = useState(false);
  //Fetch associates from superoffice
  const [associateSetting, setAssociateSetting] = useState<Associate[]>([]);
  const fetchAssociates = async () => {
    console.log("fetching associates");
    try {
      //First fetch all heading items from superoffice, by calling api/superoffice/taskheadings
      axios
        .get<Associate[]>(
          `${process.env.REACT_APP_API_URL}/api/superoffice/associates/all`,
          { withCredentials: true }
        )
        .then((res) => {
          const settings: settings | null = getSettings();
          if (settings.associates.length === 0) {
            setAssociateSetting(res.data);
          } else {
            //Loop through all associates in settings, and find the associate in the fetched associates
            let newAssociates: Associate[] = settings.associates.filter(
              (associate: Associate) => {
                if (showDeleted) {
                  return true;
                } else {
                  //find the associate in the fetched associates, if it is not found, return true
                  return (
                    res.data.filter((fetchedAssociate: Associate) => {
                      return (
                        fetchedAssociate.associateDbId ===
                        associate.associateDbId
                      );
                    }).length > 0
                  );
                }
              }
            );
            setAssociateSetting(newAssociates);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //Fetch salary employees
  const [salaryEmployees, setSalaryEmployees] = useState<Employee[]>([]);
  const fetchSalaryEmployees = async () => {
    console.log("fetching salary employees");
    try {
      //First fetch all heading items from superoffice, by calling api/superoffice/taskheadings
      axios
        .get<Employee[]>(
          `${process.env.REACT_APP_API_URL}/api/salary/${props.salaryOrg}/employee`,
          { withCredentials: true }
        )
        .then((res) => {
          console.log(res.data);
          setSalaryEmployees(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //Useeffect for fetching associates, after changing the showDeleted state
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      setAssociateSetting([]);
      await fetchAssociates();
      setLoading(false);
    };
    fetch();
  }, [showDeleted]);
  //Fetch headings on page load
  useEffect(() => {
    if (props.activeTab === "settings" && props.dineroOrg !== 0) {
      // setLoading(true);
      //Get settings from localstorage
      
      if (settings) {
        setSelectedHeading1(settings.selectedHeading1);
        setSelectedHeading2(settings.selectedHeading2);
        setSelectedHeading3(settings.selectedHeading3);
        setSelectedProduct1(settings.selectedProduct1);
        setSelectedProduct2(settings.selectedProduct2);
        setSelectedUnitType1(settings.selectedUnitType1);
        setSelectedUnitType2(settings.selectedUnitType2);
        setSelectedSaleType(settings.selectedSaleType);
        setSelectedCurrency(settings.selectedCurrency);
        setSelectedBonusType(settings.selectedBonusType);
        if (settings.associates) {
          setAssociateSetting(settings.associates);
        }
        if(pricePerUnitRef.current && settings.pricePrUnit)
          pricePerUnitRef.current.value = settings.pricePrUnit.toString();
        if(bonusTextRef.current && settings.bonusText)
          bonusTextRef.current.value = settings.bonusText;
      }

      //Call these async functions in a promise array, so they will run in parallel
      const fetcher: any = async () => {
        setLoading(true);
        Promise.all([
          fetchHeadings(),
          fetchProducts(),
          fetchSaleTypes(),
          fetchCurrencyTypes(),
          fetchAssociates(),
          fetchSalaryEmployees(),
        ])
          .then(() => {
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      };
      fetcher();
    }
  }, [props.activeTab, props.dineroOrg]);

  return (
    <>
      <MDBRow>
        <MDBCol md="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle className="mb-4">Settings</MDBCardTitle>
              {loading && (
                <MDBSpinner className="ms-2" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </MDBSpinner>
              )}
              {!loading && (
                <>
                  <MDBCardText>
                    <MDBRow className="mb-3">
                      <MDBCol size="12">
                        <MDBCard>
                          <MDBCardBody>
                            <MDBCardImage
                              src={salary_logo}
                              position="top"
                              alt="Salary logo"
                              className="img-fluid mb-5"
                              style={{ width: "100px" }}
                            />
                            <MDBCardTitle className="mb-4">
                              Associate assignment
                            </MDBCardTitle>
                            <MDBCardText>
                              <div className="scrollable-container">
                                <div>
                                  <AssociateTableRowHeader />
                                </div>
                                <div className="yscrollable-container">
                                  {associateSetting.length === 0 && (
                                    <MDBRow>
                                      <MDBCol size={12}>
                                        <span className="text-muted">
                                          No associates found
                                        </span>
                                      </MDBCol>
                                    </MDBRow>
                                  )}
                                  {associateSetting.map(
                                    (associate: Associate) => {
                                      return (
                                        <AssociateTableRow
                                          salaryEmployee={salaryEmployees}
                                          superofficeAssociate={associate}
                                          allAssociates={associateSetting}
                                          setSuperofficeAssociate={
                                            setAssociateSetting
                                          }
                                        />
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                              <MDBRow className="mt-4">
                                <MDBCol offsetMd={4} size={4}>
                                  <div
                                    style={{
                                      width: "150px",
                                      margin: "5px auto",
                                    }}
                                  >
                                    <MDBSwitch
                                      label="Show deleted"
                                      checked={showDeleted}
                                      onChange={() =>
                                        setShowDeleted(!showDeleted)
                                      }
                                    />
                                  </div>
                                </MDBCol>
                                <MDBCol size={4}>
                                  {/*price per unit*/}
                                  <MDBInput
                                    label={"Price per " + selectedUnitType2 + " (" + selectedCurrency.iso_code + ")"}
                                    className="mb-2"
                                  
                                    ref={pricePerUnitRef}
                                    />
                                  {/* 
                                  Bonus type 
                                  Select bonus type, with these options:
                                  "Bonus","Bonus No Vacation","Bonus No Pension","Bonus No Vacation and Pension"
                                  Title and value should be the same
                                  */}
                                  <MDBSelect
                                    label="Bonus type"
                                    data={[
                                      {
                                        text: "Select bonus type",
                                        value: 0,
                                      },
                                      {
                                        text: "Bonus",
                                        value: "Bonus",
                                        defaultSelected: "Bonus" === selectedBonusType,
                                      },
                                      {
                                        text: "Bonus No Vacation",
                                        value: "Bonus No Vacation",
                                        defaultSelected: "Bonus No Vacation" === selectedBonusType,
                                      },
                                      {
                                        text: "Bonus No Pension",
                                        value: "Bonus No Pension",
                                        defaultSelected: "Bonus No Pension" === selectedBonusType,
                                      },
                                      {
                                        text: "Bonus No Vacation and Pension",
                                        value: "Bonus No Vacation and Pension",
                                        defaultSelected: "Bonus No Vacation and Pension" === selectedBonusType,
                                      },
                                    ]}
                                    onValueChange={(value: any) => {
                                      setSelectedBonusType(value.value);
                                    }}
                                    className="mb-2" 
                                  />
                                  {/* Default text for bonus */}
                                  <MDBInput
                                    label="Default text for bonus"
                                    
                                    ref={bonusTextRef}
                                  />
                                   
                                </MDBCol>
                              </MDBRow>
                            </MDBCardText>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol className="mb-3" lg={6} xxl="4">
                        <MDBCard>
                          <MDBCardBody>
                            <MDBCardImage
                              src={so_logo}
                              position="top"
                              alt="SuperOffice logo"
                              className="img-fluid mb-5"
                              style={{ width: "100px" }}
                            />
                            <MDBCardTitle className="mb-4">
                              Appointment categories
                            </MDBCardTitle>
                            <MDBCardText>
                              <MDBRow>
                                <MDBCol>
                                  <MDBSelect
                                    label="Invoicable appointments"
                                    data={[
                                      {
                                        text: "Select heading",
                                        value: 0,
                                      },
                                      ...headingData.map(
                                        (heading: TaskHeading) => {
                                          return {
                                            text: heading.Name,
                                            value: heading.HeadingId,
                                            defaultSelected:
                                              heading.HeadingId ===
                                              selectedHeading1,
                                          };
                                        }
                                      ),
                                    ]}
                                    onValueChange={(value: any) => {
                                      setSelectedHeading1(value.value);
                                    }}
                                  />
                                </MDBCol>
                              </MDBRow>
                              <MDBRow className="mt-2">
                                <MDBCol>
                                  <MDBSelect
                                    label="Invoiceable driving"
                                    data={[
                                      {
                                        text: "Select heading",
                                        value: 0,
                                      },
                                      ...headingData.map(
                                        (heading: TaskHeading) => {
                                          return {
                                            text: heading.Name,
                                            value: heading.HeadingId,
                                            defaultSelected:
                                              heading.HeadingId ===
                                              selectedHeading2,
                                          };
                                        }
                                      ),
                                    ]}
                                    onValueChange={(value: any) => {
                                      setSelectedHeading2(value.value);
                                    }}
                                  />
                                </MDBCol>
                              </MDBRow>
                              <MDBRow className="mt-2">
                                <MDBCol>
                                  <MDBSelect
                                    label="Non-Invoiceable driving"
                                    data={[
                                      {
                                        text: "Select heading",
                                        value: 0,
                                      },
                                      ...headingData.map(
                                        (heading: TaskHeading) => {
                                          return {
                                            text: heading.Name,
                                            value: heading.HeadingId,
                                            defaultSelected:
                                              heading.HeadingId ===
                                              selectedHeading3,
                                          };
                                        }
                                      ),
                                    ]}
                                    onValueChange={(value: any) => {
                                      setSelectedHeading3(value.value);
                                    }}
                                  />
                                </MDBCol>
                              </MDBRow>
                            </MDBCardText>
                            <MDBCardTitle className="mb-4">
                              Sale settings
                            </MDBCardTitle>
                            <MDBCardText>
                              <MDBRow>
                                <MDBCol>
                                  <MDBSelect
                                    label="Sale type"
                                    data={[
                                      {
                                        text: "Select sale type",
                                        value: 0,
                                      },
                                      ...saleTypeData.map(
                                        (saleType: SaleType) => {
                                          return {
                                            text: saleType.name,
                                            value: saleType.saleTypeId,
                                            defaultSelected:
                                              saleType.saleTypeId ===
                                              selectedSaleType,
                                          };
                                        }
                                      ),
                                    ]}
                                    onValueChange={(value: any) => {
                                      setSelectedSaleType(value.value);
                                    }}
                                  />
                                </MDBCol>
                              </MDBRow>
                              <MDBRow className="mt-2">
                                <MDBCol>
                                  <MDBSelect
                                    label="Currency"
                                    search
                                    data={[
                                      {
                                        text: "Select currency",
                                        value: 0,
                                      },
                                      ...currencyData.map(
                                        (currency: Currency) => {
                                          return {
                                            text:
                                              currency.iso_code +
                                              " (" +
                                              currency.name +
                                              ")",
                                            value: currency.currencyId,
                                            iso_code: currency.iso_code,
                                            defaultSelected:
                                              currency.currencyId ===
                                              selectedCurrency.currencyId,
                                          };
                                        }
                                      ),
                                    ]}
                                    onValueChange={(value: any) => {
                                      setSelectedCurrency({
                                        iso_code: value.iso_code,
                                        currencyId: value.value,
                                      });
                                    }}
                                  />
                                </MDBCol>
                              </MDBRow>
                            </MDBCardText>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                      <MDBCol
                        xxl={2}
                        className="align-self-center fs-2 d-none d-xxl-block"
                      >
                        <MDBIcon fas icon="exchange-alt" />
                      </MDBCol>
                      <MDBCol className="mb-3" lg="6" xxl={6}>
                        <MDBCard>
                          <MDBCardBody>
                            <MDBCardImage
                              src={dinero_logo}
                              position="top"
                              alt="Dinero logo"
                              className="img-fluid mb-5"
                              style={{ width: "100px" }}
                            />
                            <MDBCardTitle className="mb-4">
                              Product assignment
                            </MDBCardTitle>
                            <MDBCardText>
                              <MDBRow>
                                <MDBCol size={7}>
                                  <MDBSelect
                                    label="Invoicable appointments"
                                    search
                                    data={[
                                      {
                                        text: "Select product",
                                        value: 0,
                                      },
                                      ...productData.map((product: product) => {
                                        return {
                                          text: product.Name,
                                          value: product.ProductGuid,
                                          accountNumber: product.accountNumber,
                                          defaultSelected:
                                            product.ProductGuid ===
                                            selectedProduct1.guid,
                                        };
                                      }),
                                    ]}
                                    onValueChange={(value: any) => {
                                      setSelectedProduct1({
                                        guid: value.value,
                                        accountNumber: value.accountNumber,
                                      });
                                    }}
                                  />
                                </MDBCol>
                                <MDBCol size={5}>
                                  <MDBSelect
                                    label="Unit assignment"
                                    data={[
                                      {
                                        text: "Select unit",
                                        value: 0,
                                      },
                                      ...unitTypeArray.map((unit) => {
                                        return {
                                          text: unit.text,
                                          value: unit.value,
                                          defaultSelected:
                                            unit.value === selectedUnitType1,
                                        };
                                      }),
                                    ]}
                                    onValueChange={(value: any) => {
                                      setSelectedUnitType1(value.value);
                                    }}
                                  />
                                </MDBCol>
                              </MDBRow>
                              <MDBRow className="mt-2">
                                <MDBCol size={7}>
                                  <MDBSelect
                                    label="Invoicable driving"
                                    search
                                    data={[
                                      {
                                        text: "Select product",
                                        value: 0,
                                      },
                                      ...productData.map((product: product) => {
                                        return {
                                          text: product.Name,
                                          value: product.ProductGuid,
                                          accountNumber: product.accountNumber,
                                          defaultSelected:
                                            product.ProductGuid ===
                                            selectedProduct2.guid,
                                        };
                                      }),
                                    ]}
                                    onValueChange={(value: any) => {
                                      setSelectedProduct2({
                                        guid: value.value,
                                        accountNumber: value.accountNumber,
                                      });
                                    }}
                                  />
                                </MDBCol>
                                <MDBCol size={5}>
                                  <MDBSelect
                                    label="Unit assignment"
                                    data={[
                                      {
                                        text: "Select unit",
                                        value: 0,
                                      },
                                      ...unitTypeArray.map((unit) => {
                                        return {
                                          text: unit.text,
                                          value: unit.value,
                                          defaultSelected:
                                            unit.value === selectedUnitType2,
                                        };
                                      }),
                                    ]}
                                    onValueChange={(value: any) => {
                                      setSelectedUnitType2(value.value);
                                    }}
                                  />
                                </MDBCol>
                              </MDBRow>
                            </MDBCardText>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardText>
                  <MDBFooter>
                    <MDBRow>
                      <MDBCol className="text-center">
                        <MDBBtn
                          onClick={() => {
                            //Save settings to localstorage
                            const settings: settings = {
                              ...getSettings(),
                              selectedHeading1: selectedHeading1,
                              selectedHeading2: selectedHeading2,
                              selectedHeading3: selectedHeading3,
                              selectedProduct1: selectedProduct1,
                              selectedProduct2: selectedProduct2,
                              selectedUnitType1: selectedUnitType1,
                              selectedUnitType2: selectedUnitType2,
                              selectedSaleType: selectedSaleType,
                              selectedCurrency: selectedCurrency,
                              associates: associateSetting,
                              selectedBonusType: selectedBonusType,
                              pricePrUnit: pricePerUnitRef.current?.value ? parseFloat(pricePerUnitRef.current?.value) : 0,
                              bonusText: bonusTextRef.current?.value ? bonusTextRef.current?.value : ""
                            };
                            if (validateSettings(settings)) {
                              saveSettings(settings);
                              props.changeTab("overview");
                            } else {
                              saveSettings(settings);
                              toggleShow();
                            }
                          }}
                        >
                          <MDBIcon fas icon="save" className="me-2" /> Save
                          settings
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </MDBFooter>
                </>
              )}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBModal show={confirmModal} setShow={setConfirmModal} tabIndex="-1">
        <MDBModalDialog centered size="sm">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>error</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              All settings are mandatory. Please fill out all fields.
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleShow}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};
export default SettingsTab;
