import React, { useState } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBBtn,
} from "mdb-react-ui-kit";

import { invoicingData } from "../../types/system";
interface SuccessProps {
  show: boolean;
  setShow: (show: boolean) => void;
  msg: string;
  btn: boolean;
  data: invoicingData | null;
}
const SuccessModal = (props: SuccessProps) => {
  const [showDetails, setshowDetails] = useState(false);

  const closeWindow = () => {
    window.close();
  };
  return (
    <>
      <MDBModal
        tabIndex="-1"
        open={props.show}
        onClose={() => props.setShow(false)}
      >
        <MDBModalDialog size={showDetails ? "lg" : undefined} centered>
          <MDBModalContent>
            <MDBModalBody>
              <div className="success-checkmark">
                <div className="check-icon mt-3">
                  <span className="icon-line line-tip"></span>
                  <span className="icon-line line-long"></span>
                  <div className="icon-circle"></div>
                  <div className="icon-fix"></div>
                </div>
              </div>
              <div className="text-center mt-1">
                <p className="text-center">{props.msg}</p>
                {/*props.data.sales.length > 0 && (
                  <div className="mb-3">
                    <MDBBtn
                      onClick={toggleShow}
                      color="primary"
                      className="mt-2 mb-2"
                    >
                      <MDBIcon
                        fas
                        icon={
                          !showDetails ? "angle-double-up" : "angle-double-down"
                        }
                      />
                      &nbsp; See details
                    </MDBBtn>
                    <MDBCollapse className="mt-3" show={showDetails}>
                   
                      <MDBTable align="middle" className="mt-2">
                        <MDBTableHead>
                          <tr>
                            <th scope="col">Sale name</th>
                            <th scope="col">Value</th>
                            <th scope="col">Link</th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          {props.data.sales.map((sale: any) => (
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={so_logo}
                                    alt=""
                                    style={{ width: "25px", height: "25px" }}
                                    className="rounded-circle mt-2 mb-2"
                                  />
                                  <div className="ms-3">
                                    <p className="fw-bold mb-2 mt-2">
                                      {sale.name}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="fw-normal mt-2 mb-2">
                                  {toFinalNumberFormatToString(
                                    sale.value,
                                    sale.currency
                                  )}
                                </p>
                              </td>

                              <td>
                                <p className="fw-normal mt-2 mb-2">
                                  <a
                                    href={`${process.env.REACT_APP_SUPEROFFICE_MAIN_QUOTE_LINK}?sale_id=${sale.sale_id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <MDBBadge color="primary">
                                      Open in SuperOffice
                                    </MDBBadge>
                                  </a>
                                </p>
                              </td>
                            </tr>
                          ))}
                        </MDBTableBody>
                      </MDBTable>
                    </MDBCollapse>
                  </div>
                )*/}
                {props.btn && (
                  <MDBBtn className="mt-3" onClick={() => props.setShow(false)}>
                    Close
                  </MDBBtn>
                )}
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default SuccessModal;
