import React from 'react'; 
import './App.css';
import MainPage from './components/MainPage';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'; 


const App: React.FC = () => {
  /*//Check if we are running in development mode, and if the url is already equal to REACT_APP_API_URL
  if (process.env.NODE_ENV === "development" && window.location.origin !== process.env.REACT_APP_API_URL) {
    //Redirect the user to the REACT_APP_API_URL
    if(process.env.REACT_APP_API_URL !== undefined)
    window.location.href = process.env.REACT_APP_API_URL;
  }
*/

  return (
    <div className="App">

      <Router>
        <div>
          <Routes>
            <Route path="/" element={<MainPage />} />
            {/*Add auth and callback routes, with a dynamic provider parameter*/} 
          </Routes>
        </div>
      </Router>
    </div>
  );
}



export default App;
