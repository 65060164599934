import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardText,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import React from "react";

interface OverviewElementProps {
  title: string;
  value: string;
  unit: string;
}

const OverviewElement = (props: OverviewElementProps) => {
  return (
    <>
      <MDBCard border='primary' background='white' alignment="center">
        <MDBCardHeader>{props.title}</MDBCardHeader>
        <MDBCardBody>
          <MDBCardTitle>{props.value}</MDBCardTitle>
          <MDBCardText>{props.unit}</MDBCardText>
        </MDBCardBody>
      </MDBCard>
    </>
  );
};

export default OverviewElement;
