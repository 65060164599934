import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent, 
  MDBModalBody, 
  MDBProgressBar,
} from "mdb-react-ui-kit";
import React from "react";

//mdb-react-ui-kit Modal, with a progress bar

interface ProgressModalProps {
  show: boolean;
  title: string;
  progress: number;
  setShow: (show: boolean) => void;
}

const ProgressModal = (props: ProgressModalProps) => {
  return (
    <MDBModal
      staticBackdrop
      tabIndex="-1"
      open={props.show}
      setOpen={props.setShow}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalBody>
            <h2 className="text-center">{props.title}</h2>
            <MDBProgressBar striped width={props.progress} bgColor='success' valuemin={0} valuemax={100}>
              {props.progress.toFixed(0)}%
            </MDBProgressBar>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};
export default ProgressModal;
