import { MDBCol, MDBRow, MDBSelect } from "mdb-react-ui-kit";
import React from "react";




const AssociateTableRowHeader = () => {
  return (
    <>
      <MDBRow className="AssociateTableRowHeader">
        <MDBCol size="2" className="text-start">
          SuperOffice Associate
        </MDBCol>
        <MDBCol size="3">Salary Employee</MDBCol>
        <MDBCol size="2">Bonus type</MDBCol>
        <MDBCol size="2">Threshold</MDBCol>
        <MDBCol size="1">%</MDBCol>
        <MDBCol size="2">Licenseplate</MDBCol>
      </MDBRow>
    </>
  );
};
export default AssociateTableRowHeader;
