import axios from "axios";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBAccordion,
  MDBAccordionItem,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { getSettings, getTaskTypes } from "../../utils/settings";
import { drivingTasks } from "../../types/appointment";
import { Associate } from "../../types/associate";
import DrivingTable from "../elements/drivingTable";
import { invoicingData } from "../../types/system";
/****
 *
 *
 * Tab that will hold the settings for the app, stored in localstorage.
 * These are the necessary settings:
 * - Heading in SuperOffice for invoicable appointments, as dropdown
 * - Heading for invoicable driving, as dropdown
 * - Product for invoicable appointments, as autocomplete dropdown
 * - Product for invoicable driving, as autocomplete dropdown
 * - Default unit type for invoicable appointments, as dropdown, with these options: hours, parts, km, day, week, month, kilogram, cubicMetre, set, litre, box, case, carton, metre, package, shipment, squareMetre, session, tonne. (With optimal english translation)
 * - Sale type for created sales in SuperOffice, as dropdown
 *
 */

interface SalaryTabProps {
  salaryOrg: string;
  activeTab: string;
  invoicingData: invoicingData | null;
  changeTab: (tab: string) => void;
}

const SalaryTab = (props: SalaryTabProps) => {
  const settings = getSettings();
  //Create a state for all the driving activities
  const [drivingActivities, setDrivingActivities] = useState<drivingTasks[]>(
    []
  );
  //State for showing deleted associates
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  //State for loading
  const [loading, setLoading] = useState(false);
 
  const [associateSetting, setAssociateSetting] = useState<Associate[]>([
 
  
  ]);
  const fetchAssociates = async () => {
    setLoading(true);
    console.log("fetching associates");
    try {
      //First fetch all heading items from superoffice, by calling api/superoffice/taskheadings
      axios
        .get<Associate[]>(
          `${process.env.REACT_APP_API_URL}/api/superoffice/associates/all`,
          { withCredentials: true }
        )
        .then((res) => { 
          if (settings.associates.length === 0) {
            setAssociateSetting(res.data);
          } else {
            //Loop through all associates in settings, and find the associate in the fetched associates
            let newAssociates: Associate[] = settings.associates.filter(
              (associate: Associate) => {
                if (showDeleted) {
                  return true;
                } else {
                  //find the associate in the fetched associates, if it is not found, return true
                  return (
                    res.data.filter((fetchedAssociate: Associate) => {
                      return (
                        fetchedAssociate.associateDbId ===
                        associate.associateDbId
                      );
                    }).length > 0
                  );
                }
              }
            );
            setAssociateSetting(newAssociates);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  //make use effect to fetch driving activities from superoffice
  useEffect(() => {
    if (props.activeTab === "transfer") {
      
      fetchAssociates();
    }
  }, [props.activeTab]);

  return (
    <>
      <MDBRow className="mb-3">
        <MDBCol md="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle className="mb-4">Driving tasks</MDBCardTitle>
              <MDBCardText>
                <MDBAccordion  >
                  {props.activeTab === 'transfer' && associateSetting
                    .filter((associate) => {
                      //Filter all associates that has no salary person id, and license plate
                      return (
                        associate.salaryPersonId !== undefined
                      );
                    })
                    .map((associate, index) => {
                      return (
                        <MDBAccordionItem
                          key={associate.associateDbId} 
                          headerTitle={associate.fullName}
                          collapseId={associate.associateDbId}
                        >
                          <DrivingTable 
                          allAssociates={associateSetting}
                          invoicingData={props.invoicingData}
                          associate={associate}  />
                        </MDBAccordionItem>
                      );
                    })}
                </MDBAccordion>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </>
  );
};
export default SalaryTab;
