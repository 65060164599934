import axios from "axios";
import { AppointmentType } from "../types/appointment";
import { settings, taskTypes } from "../types/system";

//Function to save and get settings from localstorage
export const saveSettings = (settings: settings) => {
  console.log("Saving settings", settings);
  localStorage.setItem("settings", JSON.stringify(settings));
};
export const getSettings = (): settings => {
  const settings = localStorage.getItem("settings");
  if (settings) {
    const parsedSettings: settings = JSON.parse(settings);
    return parsedSettings;
  }
  //If no settings are found, create a new settings object with default values
  const newSettings: settings = {
    selectedProduct1: { guid: "", accountNumber: 0 }, // {guid: "", accountNumber: 0
    selectedProduct2: { guid: "", accountNumber: 0 },
    selectedHeading1: 0,
    selectedHeading2: 0,
    selectedHeading3: 0,
    selectedUnitType1: "",
    selectedUnitType2: "",
    selectedSaleType: 0,
    selectedCurrency: { iso_code: "", currencyId: 0 },
    selectedBonusType: "",
    salaryApiUser: "",
    salaryApiPassword: "",
    associates: [],
    pricePrUnit: 0,
    bonusText: "",
  };
  saveSettings(newSettings);
  return newSettings;
};

//Function to validate if any settings are missing
export const validateSettings = (settings: settings): boolean => {
  //check if any settings are missing, by looping through the settings object to see if any of the values are 0 or ""
  for (const [, value] of Object.entries(settings)) {
    if (value === null || value === "" || value === 0) {
      return false;
    }
    //Also check if the value is an object, and if so, check if any of the values in the object are 0 or ""
    if (typeof value === "object") {
      for (const [, value2] of Object.entries(value)) {
        if (value2 === null || value2 === "" || value2 === 0) {
          return false;
        }
      }
    }
  }
  return true;
};

export const getTaskTypes = async () => {
  const settings = getSettings();
  const promisesTaskTypes: Promise<AppointmentType[]>[] = [];
  //First fetch all tasklist items from superoffice, for the invoicable tasks, by calling api/superoffice/taskheadings/ REACT_APP_DEFAULT_INVOICE_GROUP
  promisesTaskTypes.push(
    axios
      .get<AppointmentType[]>(
        `${process.env.REACT_APP_API_URL}/api/superoffice/taskheadings/${settings.selectedHeading1}`,
        { withCredentials: true }
      )
      .then((res) => {
        return res.data;
      })
  );
  //After that fetch tasklist items from superoffice, for the driving tasks
  promisesTaskTypes.push(
    axios
      .get<AppointmentType[]>(
        `${process.env.REACT_APP_API_URL}/api/superoffice/taskheadings/${settings.selectedHeading2}`,
        { withCredentials: true }
      )
      .then((res) => {
        return res.data;
      })
  );
  //After that fetch tasklist items from superoffice, for the driving tasks
  promisesTaskTypes.push(
    axios
      .get<AppointmentType[]>(
        `${process.env.REACT_APP_API_URL}/api/superoffice/taskheadings/${settings.selectedHeading3}`,
        { withCredentials: true }
      )
      .then((res) => {
        return res.data;
      })
  );

  //Wait for all promises to be resolved
  const resultsTaskTypes = await Promise.all(promisesTaskTypes);
  //Create an object with a property for each task type, holding a number array with the task ids
  const taskTypes: taskTypes = {
    invoicable: resultsTaskTypes[0].map((task) => task.Id),
    driving_invoicable: resultsTaskTypes[1].map((task) => task.Id),
    driving_non_invoicable: resultsTaskTypes[2].map((task) => task.Id),
  };
  return taskTypes;
};
