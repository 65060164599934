import React, { useEffect, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTabsItem,
  MDBIcon,
  MDBTabs,
  MDBTabsContent,
  MDBTabsLink,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import ConnectTab from "./tabs/ConnectTab";
import OverviewTab from "./tabs/OverviewTab";
import axios from "axios";
import { loggedIn } from "../types/system";
import logo from "../assets/logo.png";
import TransferTab from "./tabs/TransferTab";
import { invoicingData } from "../types/system";
import SettingsTab from "./tabs/SettingsTab";
import SalaryTab from "./tabs/SalaryTab";

export default function MainPage() {
  const [iconsActive, setIconsActive] = useState("connect");
  //State for managing if the user is logged in
  const [loggedIn, setLoggedIn] = useState<loggedIn>({
    superoffice: false,
    superoffice_ctx: "",
    dinero: false,
    salary: false,
  });
  //State that holds the selected organization in dinero
  const [dineroOrg, setDineroOrg] = useState<number>(0);
  //State for salary organization
  const [salaryOrg, setSalaryOrg] = useState<string>("");

  //State for invoicingData
  const [invoicingData, setInvoicingData] = useState<invoicingData | null>(
    null
  );
  //Fetch to update loggedIn state
  const fetchLoggedIn = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/authenticated`, {
        withCredentials: true,
      })
      .then((res: any) => {
        setLoggedIn(res.data);
      })
      .catch((err: any) => {
        console.log("error", err);
      });
  };

  //useEffect that will check if the user is logged in to superoffice and dinero
  useEffect(() => {
    fetchLoggedIn();
  }, []);

  const handleIconsClick = (value: string) => {
    if (value === iconsActive) {
      return;
    }
    setIconsActive(value);
  };

  return (
    <MDBContainer>
      <MDBRow className="mt-4">
        {/* Logo */}
        <MDBCol className="text-center">
          <img src={logo} alt="doweb logo" width="350" />
          {process.env.NODE_ENV !== "production" && (
            <p>
              {process.env.NODE_ENV} {process.env.HTTPS}
            </p>
          )}
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size={12}>
          <MDBTabs fill className="mb-3 mt-3">
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleIconsClick("connect")}
                active={iconsActive === "connect"}
              >
                <MDBIcon fas icon="key" className="me-2" /> Connect systems
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => {
                  if (loggedIn.dinero && loggedIn.superoffice && dineroOrg > 0)
                    handleIconsClick("settings");

                  handleIconsClick("settings");
                }}
                active={iconsActive === "settings"}
              >
                <MDBIcon fas icon="cog" className="me-2" /> settings
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => {
                  handleIconsClick("overview");
                  //Reset invoicingData
                  setInvoicingData(null);
                  if (
                    loggedIn.dinero &&
                    loggedIn.superoffice &&
                    dineroOrg > 0
                  ) {
                    handleIconsClick("overview");
                    //Reset invoicingData
                    setInvoicingData(null);
                  }
                }}
                active={iconsActive === "overview"}
              >
                <MDBIcon fas icon="file-invoice-dollar" className="me-2" />{" "}
                Invoice overview
              </MDBTabsLink>
            </MDBTabsItem>
    
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => {
                  if (
                    loggedIn.dinero &&
                    loggedIn.superoffice &&
                    dineroOrg > 0 &&
                    invoicingData !== null
                  )
                    handleIconsClick("transfer");
                }}
                active={iconsActive === "transfer"}
              >
                <MDBIcon fas icon="exchange-alt" className="me-2" /> Transfer
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBTabsContent>
            <MDBTabsPane open={iconsActive === "connect"}>
              <ConnectTab
                dineroOrg={dineroOrg}
                salaryOrg={salaryOrg}
                setDineroOrg={setDineroOrg}
                setSalaryOrg={setSalaryOrg}
                activeTab={iconsActive}
                changeTab={setIconsActive}
                loggedIn={loggedIn}
                fetchLoggedIn={fetchLoggedIn}
              />
            </MDBTabsPane>
            <MDBTabsPane open={iconsActive === "settings"}>
              <SettingsTab
                salaryOrg={salaryOrg}
                dineroOrg={dineroOrg}
                activeTab={iconsActive}
                changeTab={setIconsActive}
              />
            </MDBTabsPane>
            <MDBTabsPane open={iconsActive === "overview"}>
              <OverviewTab
                invoicingData={invoicingData}
                setInvoicingData={setInvoicingData}
                dineroOrg={dineroOrg}
                activeTab={iconsActive}
                changeTab={setIconsActive}
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
              />
            </MDBTabsPane> 
            <MDBTabsPane open={iconsActive === "transfer"}>
              <SalaryTab
                salaryOrg={salaryOrg}
                activeTab={iconsActive}
                invoicingData={invoicingData}
                changeTab={setIconsActive}
              />
              <TransferTab
                invoicingData={invoicingData}
                dineroOrg={dineroOrg}
                salaryOrg={salaryOrg}
                activeTab={iconsActive}
                setActiveTab={setIconsActive}
              />
            </MDBTabsPane>
          </MDBTabsContent>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
