export const unitTypeArray = [
    {
      text: "Hours",
      value: "hours",
    },
    {
      text: "Parts",
      value: "parts",
    },
    {
      text: "Kilometer",
      value: "km",
    },
    {
      text: "Day",
      value: "day",
    },
    {
      text: "Week",
      value: "week",
    },
    {
      text: "Month",
      value: "month",
    },
    {
      text: "Kilogram",
      value: "kilogram",
    },
    {
      text: "Cubic metre",
      value: "cubicMetre",
    },
    {
      text: "Set",
      value: "set",
    },
    {
      text: "Litre",
      value: "litre",
    },
    {
      text: "Box",
      value: "box",
    },
    {
      text: "Case",
      value: "case",
    },
    {
      text: "Carton",
      value: "carton",
    },
    {
      text: "Metre",
      value: "metre",
    },
    {
      text: "Package",
      value: "package",
    },
    {
      text: "Shipment",
      value: "shipment",
    },
    {
      text: "Square metre",
      value: "squareMetre",
    },
    {
      text: "Session",
      value: "session",
    },
    {
      text: "Tonne",
      value: "tonne",
    },
  ];