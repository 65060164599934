import React, { useEffect, useState } from "react";
import { Appointment, drivingTasks } from "../../../types/appointment";
import L from "leaflet";

import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import { useMap } from "react-leaflet";
import axios from "axios";
//Map view using leaflet and leaflet routing machine, to display a map with a route
//Props is going to be a list of driving tasks, that each contains a start and end address

interface MapProps {
  drivingTasks: Appointment[];
}

const Map = (props: MapProps) => {
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });
  // State to store the route controls
  const [routeControls, setRouteControls] = useState<L.Routing.Control[]>([]);
  const clearRoutes = () => {
    // Remove existing routes from the map
    routeControls.forEach((routeControl) => {
      map.removeControl(routeControl);
    });
    // Clear the state
    setRouteControls([]);
  };
  //function for reverse geocode the addresses, to get the coordinates
  const reverseGeocode = async (address: string) => {
    //Geocoding using Google Maps API
    const api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    //Fetch the coordinates using axios
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${api_key}`
    );
    //Return the coordinates as a LatLng object
    return new L.LatLng(
      response.data.results[0].geometry.location.lat,
      response.data.results[0].geometry.location.lng
    );
  };

  //Get the map from the useMap hook
  const map = useMap();

  //Create a function to draw the route on the map

  const drawRoute = async () => {
    clearRoutes(); // Clear existing routes

    let newRouteControls = [];
    let allWaypoints: L.LatLng[] = [];
    try {
      for (let i = 0; i < props.drivingTasks.length; i++) {
        // existing logic to get start and end coordinates...

        const start = await reverseGeocode(props.drivingTasks[i].startAddress);
        const end = await reverseGeocode(props.drivingTasks[i].endAddress);
        //if start is the same as allWaypoints[allWaypoints.length], then don't add it to the list
        if (
          allWaypoints.length > 0 &&
          start.equals(allWaypoints[allWaypoints.length - 1])
        ) {
          allWaypoints.push(end);
        } else {
          allWaypoints.push(start);
          allWaypoints.push(end);
        }
      }

      // Create a new route control
      const routeControl = L.Routing.control({
        waypoints: allWaypoints,
        show: false,
      }).addTo(map);

      newRouteControls.push(routeControl);
      // Update the state with the new route controls
      setRouteControls(newRouteControls);
      if (allWaypoints.length > 0) {
        const bounds = L.latLngBounds(allWaypoints);
        map.fitBounds(bounds);
      }
    } catch (error) {
      console.log(error);
      alert("Error drawing route - some of the addresses might be invalid!");
    }
  };

  useEffect(() => {
    drawRoute();
  }, [props.drivingTasks]); // Dependency array includes props.drivingTasks

  return null;
};

export default Map;
