//component that shows two MDBootstrap buttons that is providing a popup that will allow the user to login to superoffice
//and a button that will allow the user to login to dinero

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBSelect,
  MDBSpinner,
} from "mdb-react-ui-kit";
import axios from "axios";
import { loggedIn } from "../../types/system";
import so_logo from "../../assets/so_logo.png";
import dinero_logo from "../../assets/dinero_logo.png";
import salary_logo from "../../assets/salary_logo.jpeg";
import { settings } from "../../types/system";
import {
  getSettings,
  saveSettings,
  validateSettings,
} from "../../utils/settings";
import { Company } from "../../types/company";

interface IConnectTabProps {
  //Function to change tab
  changeTab: (tab: string) => void;
  //active tab
  activeTab: string;
  loggedIn: loggedIn;
  fetchLoggedIn: () => void;
  dineroOrg: number;
  salaryOrg: string;
  setDineroOrg: (org: number) => void;
  setSalaryOrg: (org: string) => void;
}

const ConnectTab = (props: IConnectTabProps) => {
  //Useref for the input field for the salary api key
  const salaryApiUserRef = useRef<HTMLInputElement>(null);
  const salaryApiPassRef = useRef<HTMLInputElement>(null);
  //State for holding the available salary companies
  const [salaryCompanies, setSalaryCompanies] = useState<
    { text: string; value: string }[]
  >([]);
  //State for loadingSalaryAuth
  const [loadingSalaryAuth, setLoadingSalaryAuth] = useState<boolean>(false);
  //State that holds the dropdown options for organizations in dinero
  const [dineroOrgs, setDineroOrgs] = useState<
    { text: string; value: string }[]
  >([]);
  //State for holding state of loading organizations
  const [loadingDinero, setLoadingDinero] = useState<boolean>(true);
  const [loadingSalary, setLoadingSalary] = useState<boolean>(true);
  //State for holding settings
  const [settings, setSettings] = useState<settings | null>(null);
  //Get the selected organization from a cookie
  const dineroOrgCookie = document.cookie
    .split(";")
    .find((row) => row.trimStart().startsWith("dineroOrg"));
  if (dineroOrgCookie) {
    const dineroOrg = parseInt(dineroOrgCookie.split("=")[1]);
    props.setDineroOrg(dineroOrg);

  }
  //Get the selected organization from a cookie (Salary)
  const salaryOrgCookie = document.cookie
    .split(";")
    .find((row) => row.trimStart().startsWith("salaryOrg"));
  if (salaryOrgCookie) {
    const salaryOrg = salaryOrgCookie.split("=")[1];
    props.setSalaryOrg(salaryOrg);
  }
  const { dineroOrg, setDineroOrg, loggedIn, salaryOrg, setSalaryOrg } = props;

  const fetchOrganizations = useCallback(async () => {
    setLoadingDinero(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/dinero/organizations`, {
        withCredentials: true,
      })
      .then((res: any) => {
        console.log("organizations", res.data);
        if (dineroOrg === 0 && res.data.length > 0) {
          setDineroOrg(res.data[0].id);
        }
        console.log("dineroOrgCookie", dineroOrg);
        //filter everything that is not pro
        setDineroOrgs(
          res.data
            .filter((org: any) => org.isPro)
            .map((org: any) => {
              return {
                text: org.name,
                value: org.id,
                defaultSelected: org.id === dineroOrg,
              };
            })
        );
        setLoadingDinero(false);
      })
      .catch((err: any) => {
        console.log("error", err);
        setLoadingDinero(false);
      });
  }, [dineroOrg, setDineroOrg]);

  //useCallback that will fetch the available salary companies
  const fetchSalaryCompanies = useCallback(async () => {
    setLoadingSalary(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/salary/company`, {
        withCredentials: true,
      })
      .then((res: any) => {
        console.log("companies", res.data.data);
        if (salaryOrg === "" && res.data.length > 0) {
          setSalaryOrg(res.data.data[0].id);
        }
        console.log("salaryOrgCookie", salaryOrg);
        //filter everything that is not pro
        setSalaryCompanies(
          res.data.data.map((company: Company) => {
            return {
              text: company.name,
              value: company.id,
              defaultSelected: company.id === salaryOrg,
            };
          })
        );
        setLoadingSalary(false);
      })
      .catch((err: any) => {
        console.log("error", err);
        setLoadingSalary(false);
      });
  }
  , [salaryOrg, setSalaryOrg]);

  useEffect(() => {
    if (loggedIn.dinero) {
      fetchOrganizations();
    }
    if (loggedIn.salary) {
      fetchSalaryCompanies();
    }
    if (props.activeTab === "connect") {
      //update settings
      setSettings(getSettings());
      if (settings) console.log("settings", validateSettings(settings));
    }
  }, [loggedIn.dinero, fetchOrganizations, props.activeTab, loggedIn.salary, fetchSalaryCompanies]);

  //useeffect that will load the api key from the settings if it exists
  useEffect(() => {
    if (settings) {
      salaryApiUserRef.current!.value = settings.salaryApiUser;
      salaryApiPassRef.current!.value = settings.salaryApiPassword;
    }
  }, [settings]);

  const handleOrgChange = (value: number) => {
    console.log("Setting Dinero Org with value:", value);

    //Add the selected organization to a cookie, but keep the salaryOrg
    document.cookie = `dineroOrg=${value};salaryOrg=${salaryOrg}`;
    //refresh the page
    window.location.reload();
  };
  const handleSalaryChange = (value: string) => {
    console.log("Setting Salary Org with value:", value);
    //Add the selected organization to a cookie, but keep the dineroOrg
    document.cookie = `salaryOrg=${value};dineroOrg=${dineroOrg}`;
    //refresh the page
    window.location.reload();
  }
  const handleSalaryApiUserChange = () => {
    // Update the settings with the new api key, and save it
    console.log("salaryApiUserRef", salaryApiUserRef.current!.value);
    const set = {
      ...settings!,
      salaryApiUser: salaryApiUserRef.current!.value,
      salaryApiPassword: salaryApiPassRef.current!.value,
    };
    saveSettings(set);
    //get the settings
    setSettings(set);
    console.log("settings", settings);
  };

  //Handle salary authentication, by posting username and password to the backend
  const handleSalaryLogin = async () => {
    handleSalaryApiUserChange();
    setLoadingSalaryAuth(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/auth/salary`,
        {
          username: salaryApiUserRef.current!.value,
          password: salaryApiPassRef.current!.value,
        },
        { withCredentials: true }
      )
      .then((res: any) => {
        console.log("res", res.data);
        if (res.data.error) {
          //Update the loggedIn state
          alert(res.data.error.message);
          props.fetchLoggedIn();
        } else if (res.data.loggedIn) {
          props.fetchLoggedIn();
        }
        setLoadingSalaryAuth(false);
      })
      .catch((err: any) => {
        console.log("error", err);
        setLoadingSalaryAuth(false);
      });
  };

  return (
    <>
      <MDBRow>
        <MDBCol md="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle className="mb-4">
                Connect to your systems
              </MDBCardTitle>
              <MDBCardText>
                <MDBRow>
                  <MDBCol sm={12} md={2}>
                    <img src={so_logo} alt="SuperOffice logo" width="75" />
                  </MDBCol>
                  <MDBCol className="mb-3" sm={12} md={10}>
                    <div className="d-grid mt-3">
                      <MDBBtn
                        color={
                          props.loggedIn.superoffice ? "success" : "primary"
                        }
                        onClick={() => {
                          //redirect to the OAuth2 endpoint for superoffice
                          window.location.href = `${process.env.REACT_APP_API_URL}/auth/superoffice`;
                        }}
                      >
                        Connect{props.loggedIn.superoffice ? "ed" : ""} to
                        SuperOffice
                      </MDBBtn>
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol sm={12} className="mt-2" md={2}>
                    <img src={dinero_logo} alt="Dinero logo" width="50" />
                  </MDBCol>
                  <MDBCol sm={12} md={props.loggedIn.dinero ? "4" : "10"}>
                    <div className="d-grid mt-3">
                      <MDBBtn
                        color={props.loggedIn.dinero ? "success" : "primary"}
                        onClick={() => {
                          //redirect to the OAuth2 endpoint for dinero
                          window.location.href = `${process.env.REACT_APP_API_URL}/auth/dinero`;
                        }}
                      >
                        Connect{props.loggedIn.dinero ? "ed" : ""} to Dinero
                      </MDBBtn>
                    </div>
                  </MDBCol>
                  {props.loggedIn.dinero && loadingDinero && (
                    <MDBCol className="mb-3" sm={12} md={6}>
                      <div className="d-flex justify-content-center mt-3">
                        <MDBSpinner role="status">
                          <span className="visually-hidden">Loading...</span>
                        </MDBSpinner>
                      </div>
                    </MDBCol>
                  )}
                  {props.loggedIn.dinero &&
                    dineroOrgs.length === 0 &&
                    !loadingDinero && (
                      <MDBCol className="mb-3" sm={12} md={6}>
                        <p className="mt-3">
                          You are logged in to Dinero, but you do not have
                          access to any organizations. Please login to Dinero
                          and add an organization.
                        </p>
                      </MDBCol>
                    )}

                  {props.loggedIn.dinero &&
                    dineroOrgs.length > 0 &&
                    !loadingDinero && (
                      <MDBCol className="mb-3" sm={12} md={6}>
                        {/* Show a drop down with available organizations in Dinero */}
                        <MDBSelect
                          label="Select Dinero organization"
                          className="mt-3"
                          data={dineroOrgs}
                          onValueChange={(value: any) =>
                            handleOrgChange(value.value)
                          }
                        />
                      </MDBCol>
                    )}
                </MDBRow>
                <MDBRow>
                  <MDBCol sm={12} md={2}>
                    <img
                      src={salary_logo}
                      alt="Salary logo"
                      width="50"
                      style={{ marginTop: "10px" }}
                    />
                  </MDBCol>
                  <MDBCol sm={12} md={4}>
                    <div className="d-grid mt-3">
                      <MDBBtn
                        color={props.loggedIn.salary ? "success" : "primary"}
                        disabled={loadingSalaryAuth}
                        onClick={handleSalaryLogin}
                      >
                        Connect{props.loggedIn.salary ? "ed" : ""} to Salary
                      </MDBBtn>
                    </div>
                    {props.loggedIn.salary && loadingSalary && (
             
                      <div className="d-flex justify-content-center mt-3">
                        <MDBSpinner role="status">
                          <span className="visually-hidden">Loading...</span>
                        </MDBSpinner>
                      </div>
                 
                  )}
                    {props.loggedIn.salary &&
                      salaryCompanies.length === 0 &&
                      !loadingSalary && (
                        <MDBCol size={12}>
                          <p className="mt-3">
                            You are logged in to Salary, but you do not have
                            access to any organizations. Please login to Salary
                            and add an organization.
                          </p>
                        </MDBCol>
                      )}

                    {props.loggedIn.salary &&
                      salaryCompanies.length > 0 &&
                      !loadingSalary && (
                        <MDBCol size={12}>
                          {/* Show a drop down with available organizations in Dinero */}
                          <MDBSelect
                            label="Select Salary company"
                            className="mt-3"
                            data={salaryCompanies}
                            onValueChange={(value: any) =>
                               handleSalaryChange(value.value) 
                            }
                          />
                        </MDBCol>
                      )}
                  </MDBCol>
                  <MDBCol sm={12} md={6}>
                    {/* input api key, and load / save it to cookies */}
                    <MDBInput
                      label="Salary Username"
                      className="mt-3"
                      defaultValue={settings?.salaryApiUser}
                      ref={salaryApiUserRef}
                    />
                    <MDBInput
                      label="Salary Password"
                      className="mt-3"
                      defaultValue={settings?.salaryApiPassword}
                      ref={salaryApiPassRef}
                      type="password"
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mt-3">
                  <MDBCol size={12} className="text-end">
                    <MDBBtn
                      disabled={
                        !props.loggedIn.dinero ||
                        !props.loggedIn.superoffice ||
                        props.dineroOrg === 0
                      }
                      color="primary"
                      onClick={() => {
                        if (settings && validateSettings(settings)) {
                          props.changeTab("overview");
                        } else {
                          props.changeTab("settings");
                        }
                      }}
                    >
                      Next <MDBIcon icon="arrow-right" className="ms-2" />
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </>
  );
};
export default ConnectTab;
