import React, { useEffect, useState } from "react";
import {
  MDBBadge,
  MDBCard,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
} from "mdb-react-ui-kit";
import { Associate } from "../../../types/associate";
import { Appointment, drivingTasks } from "../../../types/appointment";
import Map from "./map";
import { MapContainer, TileLayer } from "react-leaflet";
import { LatLng } from "leaflet";
import "leaflet/dist/leaflet.css";
import { unitTypeArray } from "../../../utils/units";
import { getSettings } from "../../../utils/settings";
import axios from "axios";
import Overview from "./overview";
import { invoicingData, userStatistics } from "../../../types/system";
import ChangeAppointment from "../../modals/ChangeAppointment";
import { groupedDrivingTasks } from "../../../types/carAllowance";
interface drivingTableProps {
  associate: Associate;
  allAssociates: Associate[];
  invoicingData: invoicingData | null;
}

const DrivingTable = (props: drivingTableProps) => {
  //State for showing the change appointment modal
  const [showChangeAppointment, setShowChangeAppointment] =
    useState<boolean>(false);
  //State for holding the active appointment
  const [activeAppointment, setActiveAppointment] = useState<Appointment>({
    appointmentId: 0,
    associateName: "",
    associatePersonId: 0,
    contactId: 0,
    contactName: "",
    date: "",
    endDate: "",
    factor: 0,
    title: "",
    text: "",
    textHtml: "",
    totalPrice: 0,
    type: "",
    duration: 0,
    associateId: 0,
    dineroNo: "",
    hourlyPrice: 0,
    personId: 0,
    personName: "",
    typeId: 0,
    effectiveHourlyPrice: 0,
    drivingDistance: 0,
    drivingPrice: 0,
    isDriving: false,
    endAddress: "",
    startAddress: "",
  }); //The appointment that is currently being edited

  //This component will provide an interface containing a google map for each route that the associate has driven
  const settings = getSettings();
  //State for driving tasks
  const [drivingTaskList, setDrivingTaskList] = useState<Appointment[]>([]);
  //Useeffect that will fetch the driving tasks
  const fetchDriving = async () => {
    //Fetch the driving tasks with axios
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/superoffice/drivingtasks/${props.associate.associateDbId}`,
        {
          withCredentials: true,
        }
      )
      .then((res: any) => {
        //Set the driving tasks
        setDrivingTaskList(res.data);
      })
      .catch((err: any) => {
        console.log("error", err);
      });
  };
  useEffect(() => {
    fetchDriving();
  }, []);

  //Create a state for the grouped driving tasks
  const [groupedDrivingTasks, setGroupedDrivingTasks] = useState<
    groupedDrivingTasks[]
  >([]);
  //State for holding the active index
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  //Function for grouping driving tasks by date
  const groupDrivingTasks = (drivingTasks: Appointment[]) => {
    //First create an array of all the dates
    let dates: string[] = [];
    drivingTasks.forEach((drivingTask) => {
      //Check if the date is already in the array
      if (!dates.includes(drivingTask.date.split("T")[0])) {
        dates.push(drivingTask.date.split("T")[0]);
      }
    });
    //Create an array of grouped driving tasks
    let groupedDrivingTasks: groupedDrivingTasks[] = [];
    dates.forEach((date) => {
      //Filter the driving tasks by date
      let drivingTasksByDate: Appointment[] = drivingTasks.filter(
        (drivingTask) => {
          return drivingTask.date.split("T")[0] === date;
        }
      );
      //Add the driving tasks to the grouped driving tasks
      groupedDrivingTasks.push({
        date: date,
        drivingTasks: drivingTasksByDate,
      });
    });
    //Set the grouped driving tasks
    setGroupedDrivingTasks(groupedDrivingTasks);
  };
  //UseEffect that will group the driving tasks by date
  useEffect(() => {
    groupDrivingTasks(drivingTaskList);
  }, [drivingTaskList]);
  const position = new LatLng(55.396, 10.3908);
  return (
    <>
      <MDBRow>
        <MDBCol size="3" className="text-start">
          <MDBListGroup light>
            <MDBListGroupItem
              key={-1}
              active={activeIndex === -1}
              onClick={() => {
                setActiveIndex(-1);
              }}
              className="d-flex justify-content-between align-items-center px-3"
            >
              Overview
            </MDBListGroupItem>
          </MDBListGroup>
          {drivingTaskList.length > 0 && (
            <h6 className="bg-light mt-2 p-2 border-top border-bottom">
              Driving Overview
            </h6>
          )}
          <MDBListGroup light>
            {groupedDrivingTasks.map((groupedDrivingTask, index) => {
              return (
                <MDBListGroupItem
                  key={index}
                  style={{ cursor: "pointer" }}
                  active={activeIndex === index}
                  onClick={() => {
                    setActiveIndex(index);
                  }}
                  className="d-flex justify-content-between align-items-center px-3"
                >
                  {
                    /*Convert yyyy-mm-dd date into local from string */
                    groupedDrivingTask.date.split("-")[2] +
                      "/" +
                      groupedDrivingTask.date.split("-")[1] +
                      "/" +
                      groupedDrivingTask.date.split("-")[0]
                  }
                  {groupedDrivingTask.drivingTasks.filter(
                    (drivingTask: Appointment) =>
                      drivingTask.drivingDistance < 1 ||
                      drivingTask.text.length === 0 ||
                      drivingTask.startAddress === "" ||
                      drivingTask.endAddress === ""
                  ).length > 0 && (
                    <MDBBadge pill color="danger">
                      <MDBIcon
                        fas
                        icon="exclamation-triangle"
                        className="me-1"
                      />
                    </MDBBadge>
                  )}
                  <MDBBadge pill light>
                    {groupedDrivingTask.drivingTasks.length}
                  </MDBBadge>
                </MDBListGroupItem>
              );
            })}
          </MDBListGroup>
        </MDBCol>
        <MDBCol size="9" style={{ height: "700px" }}>
          <MDBCard style={{ height: "100%" }}>
            <MDBCardTitle>
              {activeIndex !== -1 && (
                <MDBRow>
                  <MDBCol size={4}>
                    {/*total distance */}
                    <h2 className="mt-2">
                      {groupedDrivingTasks[activeIndex] &&
                        groupedDrivingTasks[activeIndex].drivingTasks.reduce(
                          (a, b) => a + b.drivingDistance,
                          0
                        )}
                    </h2>
                    {
                      unitTypeArray.find(
                        (unit) => unit.value === settings.selectedUnitType2
                      )?.text
                    }
                  </MDBCol>
                  <MDBCol size={4}>
                    {/*total price */}
                    <h2 className="mt-2">
                      {groupedDrivingTasks[activeIndex] &&
                        (
                          groupedDrivingTasks[activeIndex].drivingTasks.reduce(
                            (a, b) => a + b.drivingDistance,
                            0
                          ) * settings.pricePrUnit
                        ).toFixed(2)}
                    </h2>
                    {settings.selectedCurrency.iso_code}
                  </MDBCol>
                  <MDBCol size={4}>
                    {/*total time */}
                    <h2 className="mt-2">
                      {groupedDrivingTasks[activeIndex] &&
                        groupedDrivingTasks[activeIndex].drivingTasks.reduce(
                          (a, b) => a + b.duration,
                          0
                        )}
                    </h2>
                    Hours
                  </MDBCol>
                </MDBRow>
              )}
              {activeIndex === -1 && <h2 className="mt-2">Overview</h2>}
            </MDBCardTitle>
            <MDBCardText className="h-100">
              {activeIndex === -1 && (
                <>
                  <Overview
                    key={props.associate.associateDbId}
                    groupedDrivingTasks={groupedDrivingTasks}
                    fullTurnover={
                      props.invoicingData?.allStatistics.totalStatistics
                        .totalPrice || 0
                    }
                    associate={props.associate}
                    currency={settings.selectedCurrency.iso_code}
                    drivingUnit={
                      unitTypeArray.find(
                        (unit) => unit.value === settings.selectedUnitType2
                      )?.text || ""
                    }
                    ownTurnover={
                      props.invoicingData
                        ? props.invoicingData.allStatistics.userStatistics.find(
                            (userStatistic: userStatistics) => {
                              return (
                                userStatistic.associateId ===
                                props.associate.associateDbId
                              );
                            }
                          )?.statistics.totalPrice || 0
                        : 0
                    }
                    teamTurnover={
                      /* use the props.associate.usergroup, to figure out which associates are in the same team, and then combine the "own" turnover of team mates */
                      props.invoicingData
                        ? props.invoicingData.allStatistics.userStatistics
                            .filter((userStatistic: userStatistics) => {
                              //array of all associates in the same group
                              const groupAssociateIds = props.allAssociates
                                .filter(
                                  (associate: Associate) =>
                                    associate.usergroup ===
                                    props.associate.usergroup
                                )
                                .map(
                                  (associate: Associate) =>
                                    associate.associateDbId
                                );
                              return groupAssociateIds.includes(
                                userStatistic.associateId
                              );
                            })
                            .reduce((a: number, b: userStatistics) => {
                              return a + b.statistics.totalPrice;
                            }, 0) || 0
                        : 0
                    }
                    totalDrivingDuration={
                      groupedDrivingTasks.reduce(
                        (a, b) =>
                          a +
                          b.drivingTasks.reduce((c, d) => c + d.duration, 0),
                        0
                      ) || 0
                    }
                    totalDrivingValue={
                      groupedDrivingTasks.reduce(
                        (a, b) =>
                          a +
                          b.drivingTasks.reduce(
                            (c, d) => c + d.drivingDistance,
                            0
                          ) *
                            settings.pricePrUnit,
                        0
                      ) || 0
                    }
                    totalKm={
                      groupedDrivingTasks.reduce(
                        (a, b) =>
                          a +
                          b.drivingTasks.reduce(
                            (c, d) => c + d.drivingDistance,
                            0
                          ),
                        0
                      ) || 0
                    }
                  />
                </>
              )}
              {activeIndex !== -1 && (
                <MDBRow className="h-100">
                  <MDBCol size={8}>
                    <MapContainer
                      center={position}
                      zoom={4}
                      style={{ height: "100%" }}
                    >
                      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                      <Map
                        drivingTasks={
                          groupedDrivingTasks[activeIndex].drivingTasks
                        }
                      />
                    </MapContainer>
                  </MDBCol>
                  <MDBCol size={4}>
                    <MDBListGroup>
                      {groupedDrivingTasks[activeIndex].drivingTasks.map(
                        (drivingTask: Appointment, index) => {
                          return (
                            <MDBListGroupItem
                              key={index}
                              style={{ cursor: "pointer" }}
                              active={
                                activeAppointment.appointmentId ===
                                  drivingTask.appointmentId &&
                                showChangeAppointment
                              }
                              className="d-flex justify-content-between align-items-center px-3 me-1"
                              onClick={() => {
                                setActiveAppointment({
                                  ...drivingTask,
                                  isDriving: true,
                                });
                                setShowChangeAppointment(true);
                              }}
                            >
                              <span className="text-truncate">
                                {drivingTask.contactName}
                              </span>
                              <MDBBadge pill light>
                                {drivingTask.drivingDistance.toFixed(2) +
                                  " " +
                                  unitTypeArray.find(
                                    (unit) =>
                                      unit.value === settings.selectedUnitType2
                                  )?.text}
                              </MDBBadge>
                            </MDBListGroupItem>
                          );
                        }
                      )}
                    </MDBListGroup>
                  </MDBCol>
                </MDBRow>
              )}
            </MDBCardText>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <ChangeAppointment
        showModal={showChangeAppointment}
        setShowModal={setShowChangeAppointment}
        refreshAppointments={fetchDriving}
        appointment={activeAppointment}
      />
    </>
  );
};
export default DrivingTable;
