import React, { useEffect, useRef, useState } from "react";

//Modal using MDBootstrap, that allows the person to create a new contact in dinero, based on the customer data passed as props

import {
  MDBInput,
  MDBModalBody,
  MDBSelect,
  MDBModalFooter,
  MDBBtn,
} from "mdb-react-ui-kit";
import { contact } from "../../../types/contact";
import axios from "axios";
interface ICreateDineroContactForm {
  superofficeContact: contact | null;
  dineroOrgNo: number;
  setDineroContactGuid: (contactGuid: string) => void;
  setDineroContactName: (dineroContactName: string) => void;
  setLoading: (loading: boolean) => void;
  setCreateNewContact: (createNewContact: boolean) => void;
}

const CreateDineroContactForm = (props: ICreateDineroContactForm) => {
  const [paymentConditionType, setPaymentConditionType] =
    useState<string>("Netto");

  const nameRef = useRef<HTMLInputElement>(null);
  const streetRef = useRef<HTMLInputElement>(null);
  const zipCodeRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const countryRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const webpageRef = useRef<HTMLInputElement>(null);
  const attPersonRef = useRef<HTMLInputElement>(null);
  const vatNumberRef = useRef<HTMLInputElement>(null);
  const eanNumberRef = useRef<HTMLInputElement>(null);
  const seNumberRef = useRef<HTMLInputElement>(null);
  const pNumberRef = useRef<HTMLInputElement>(null);
  const paymentConditionNumberOfDaysRef = useRef<HTMLInputElement>(null);

  //useEffect to update refs from props (superofficeContact)
  useEffect(() => {
    if (props.superofficeContact) {
      nameRef.current!.value = props.superofficeContact.name;

      streetRef.current!.value = props.superofficeContact.street;
      zipCodeRef.current!.value = props.superofficeContact.zipCode;
      cityRef.current!.value = props.superofficeContact.city;
      countryRef.current!.value = props.superofficeContact.countryKey;
      phoneRef.current!.value = props.superofficeContact.phone;
      emailRef.current!.value = props.superofficeContact.email;
      webpageRef.current!.value = props.superofficeContact.webpage;
      attPersonRef.current!.value = props.superofficeContact.attPerson;
      vatNumberRef.current!.value = props.superofficeContact.vatNumber;
      eanNumberRef.current!.value = props.superofficeContact.eanNumber;
      seNumberRef.current!.value = props.superofficeContact.seNumber;
      pNumberRef.current!.value = props.superofficeContact.pNumber;
      paymentConditionNumberOfDaysRef.current!.value =
        props.superofficeContact.paymentConditionNumberOfDays.toString();
    }
  }, [props.superofficeContact]);

  const handleDineroCreateContact = async () => {
    props.setLoading(true);
    const name = nameRef.current!.value;
    const street = streetRef.current!.value;
    const zipCode = zipCodeRef.current!.value;
    const city = cityRef.current!.value;
    const country = countryRef.current!.value;
    const phone = phoneRef.current!.value;
    const email = emailRef.current!.value;
    const webpage = webpageRef.current!.value;
    const attPerson = attPersonRef.current!.value;
    const vatNumber = vatNumberRef.current!.value;
    const eanNumber = eanNumberRef.current!.value;
    const seNumber = seNumberRef.current!.value;
    const pNumber = pNumberRef.current!.value;
    const paymentConditionNumberOfDays =
      paymentConditionNumberOfDaysRef.current!.value;
    if (props.superofficeContact === null) return;
    const data: contact = {
      externalReference: props.superofficeContact.externalReference,
      name,
      street,
      zipCode,
      city,
      countryKey: country,
      phone,
      email,
      webpage,
      attPerson,
      vatNumber,
      eanNumber,
      seNumber,
      pNumber,
      paymentConditionType,
      paymentConditionNumberOfDays: parseInt(paymentConditionNumberOfDays),
      useCvr: vatNumber !== "",
      isMember: false,
      isPerson: false,
    };

    console.log("data", data);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/dinero/${props.dineroOrgNo}/contact`,
        data,
        { withCredentials: true }
      );
      console.log("dinero contact created", res.data);
      props.setDineroContactGuid(res.data.ContactGuid);
      props.setDineroContactName(data.name);
    } catch (err: any) {
      console.log(err);
    }
    props.setLoading(false);
  };

  return (
    <>
      <MDBModalBody>
        <MDBInput
          label="Name"
          id="name"
          type="text"
          ref={nameRef}
          className="mt-3"
        />
        <MDBInput
          label="Street"
          id="street"
          type="text"
          ref={streetRef}
          className="mt-3"
        />
        <MDBInput
          label="Zip code"
          id="zipCode"
          type="text"
          ref={zipCodeRef}
          className="mt-3"
        />
        <MDBInput
          label="City"
          id="city"
          type="text"
          ref={cityRef}
          className="mt-3"
        />
        <MDBInput
          label="Country"
          id="country"
          type="text"
          ref={countryRef}
          className="mt-3"
        />
        <MDBInput
          label="Phone"
          id="phone"
          type="text"
          ref={phoneRef}
          className="mt-3"
        />
        <MDBInput
          label="Email"
          id="email"
          type="text"
          ref={emailRef}
          className="mt-3"
        />
        <MDBInput
          label="Webpage"
          id="webpage"
          ref={webpageRef}
          type="text"
          className="mt-3"
        />
        <MDBInput
          label="Att person"
          id="attPerson"
          type="text"
          ref={attPersonRef}
          className="mt-3"
        />
        <MDBInput
          label="Vat number"
          id="vatNumber"
          type="text"
          ref={vatNumberRef}
          className="mt-3"
        />
        <MDBInput
          label="Ean number"
          id="eanNumber"
          type="text"
          ref={eanNumberRef}
          className="mt-3"
        />
        <MDBInput
          label="Se number"
          id="seNumber"
          type="text"
          ref={seNumberRef}
          className="mt-3"
        />
        <MDBInput
          label="P number"
          id="pNumber"
          type="text"
          ref={pNumberRef}
          className="mt-3"
        />
        <MDBSelect
          label="Payment condition type"
          id="paymentConditionType"
          onValueChange={(value: any) => setPaymentConditionType(value.value)}
          className="mt-3"
          data={[
            {
              text: "Netto",
              value: "Netto",
            },
            {
              text: "NettoCash",
              value: "NettoCash",
            },
            {
              text: "CurrentMonthOut",
              value: "CurrentMonthOut",
            },
          ]}
        />
        <MDBInput
          label="Payment condition number of days"
          id="paymentConditionNumberOfDays"
          type="number"
          ref={paymentConditionNumberOfDaysRef}
          className="mt-3"
        />
        {/* button for creating a new customer */}
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn
          color="secondary"
          onClick={() => props.setCreateNewContact(false)}
        >
          Close
        </MDBBtn>
        <MDBBtn
          color="primary"
          onClick={() => {
            handleDineroCreateContact();
          }}
        >
          Save
        </MDBBtn>
      </MDBModalFooter>
    </>
  );
};
export default CreateDineroContactForm;
