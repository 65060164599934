//Write a MDBAccordian compnonent that list items from a list called customers

import axios from "axios";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBAccordion,
  MDBAccordionItem,
  MDBDatatable,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBBadge,
} from "mdb-react-ui-kit";
import { useState, useEffect, useCallback } from "react";
import ChangeAppointment from "../modals/ChangeAppointment";
import {
  CustomerOverview,
  Appointment,
  AppointmentType,
} from "../../types/appointment";
import CreateDineroContact from "../modals/CreateDineroContact";
import { invoicingData, loggedIn, taskTypes } from "../../types/system";
import { getSettings, getTaskTypes } from "../../utils/settings";
import { unitTypeArray } from "../../utils/units";

interface ICustomersProps {
  //Function to change tab
  changeTab: (tab: string) => void;
  //active tab
  activeTab: string;
  loggedIn: loggedIn;
  setLoggedIn: (loggedIn: loggedIn) => void;
  dineroOrg: number;
  invoicingData: invoicingData | null;
  setInvoicingData: (invoicingData: invoicingData | null) => void;
}

const OverviewTab = (props: ICustomersProps) => {
  const [customers, setCustomers] = useState<CustomerOverview[]>([]);
  const [loading, setLoading] = useState(true);
  //state for settings
  const settings = getSettings();

  const [activeAppointment, setActiveAppointment] = useState<Appointment>({
    appointmentId: 0,
    associateName: "",
    associatePersonId: 0,
    contactId: 0,
    contactName: "",
    date: "",
    endDate: "",
    factor: 0,
    title: "",
    text: "",
    textHtml: "",
    totalPrice: 0,
    type: "",
    duration: 0,
    associateId: 0,
    dineroNo: "",
    hourlyPrice: 0,
    personId: 0,
    personName: "",
    typeId: 0,
    effectiveHourlyPrice: 0,
    drivingDistance: 0,
    drivingPrice: 0,
    isDriving: false,
    endAddress: "",
    startAddress: "",
  }); //The appointment that is currently being edited
  const [showChangeAppointment, setShowChangeAppointment] =
    useState<boolean>(false); //State for showing the change appointment modal
  const [showCreateDineroContact, setShowCreateDineroContact] =
    useState<boolean>(false); //State for showing the create dinero contact modal
  const [activeContact, setActiveContact] = useState<{
    contactId: number;
    dineroNo: string;
  }>({
    contactId: 0,
    dineroNo: "",
  }); //The contact that is currently being edited
  const fetchAppointments = useCallback(async () => {
    try {
      setLoading(true);
      //Array for holding promises, so that we can execute them in parallel. Each promise will return a AppointmentType array
      const taskTypes: taskTypes = await getTaskTypes();
      console.log("taskTypes", taskTypes);

      //Now create a promise for each task type, so that we can execute them in parallel
      const promisesAppointments: Promise<CustomerOverview[]>[] = [];
      //First each category of appointments, by adding a parameter named type to the url, taking the ids as a comma separated string
      promisesAppointments.push(
        axios
          .get<CustomerOverview[]>(
            `${
              process.env.REACT_APP_API_URL
            }/api/superoffice/appointment?type=${taskTypes.invoicable.join(
              ","
            )}`,
            { withCredentials: true }
          )
          .then((res) => {
            return res.data;
          })
      );
      //After that fetch tasklist items from superoffice, for the driving tasks
      promisesAppointments.push(
        axios
          .get<CustomerOverview[]>(
            `${
              process.env.REACT_APP_API_URL
            }/api/superoffice/appointment?type=${taskTypes.driving_invoicable.join(
              ","
            )}`,
            { withCredentials: true }
          )
          .then((res) => {
            return res.data;
          })
      );
      //Wait for all promises to be resolved, catch any errors
      const resultsAppointments = await Promise.all(promisesAppointments);
      //Now merge the two arrays into one, contactId is the unique identifier, and each appointment should be unique.
      //If the same appointment appears in both arrays, it's property isDriving will be set to false
      const mergedAppointments: CustomerOverview[] = resultsAppointments[0]; //Invoiceable tasks is the master
      //Set driving to false for all appointments inside each customer in the invoicable tasks
      mergedAppointments.forEach((customer) => {
        customer.appointments.forEach((appointment) => {
          appointment.isDriving = false;
        });
      });
      //For the second array, mark all appointments as driving
      resultsAppointments[1].forEach((customer: CustomerOverview) => {
        customer.totalPrice = customer.totalDrivingPrice;
        customer.appointments.forEach((appointment) => {
          appointment.isDriving = true;
        });
      });
      //Now add the driving tasks to mergedAppointments - be sure to set isDriving to true only if the appointment does not already exist in the array
      //Merge the customer first, or create it if it does not exist
      resultsAppointments[1].forEach((customer) => {
        const existingCustomer = mergedAppointments.find(
          (row) => row.contactId === customer.contactId
        );
        if (existingCustomer) {
          //Customer already exists, add the appointments
          customer.appointments.forEach((appointment) => {
            const existingAppointment = existingCustomer.appointments.find(
              (row) => row.appointmentId === appointment.appointmentId
            );
            if (!existingAppointment) {
              //Appointment does not exist, add it
              appointment.isDriving = true;
              existingCustomer.appointments.push(appointment);
            }
          });
          //Update the total price
          existingCustomer.totalPrice += customer.totalDrivingPrice;
          //And set the driving price
          existingCustomer.totalDrivingPrice = customer.totalDrivingPrice;
        } else {
          //Customer does not exist, add it after changing the isDriving property on each appointment
          mergedAppointments.push(customer);
        }
      });
      //Remove all customers that have 0 in totalPrice and totalDrivingPrice
      const filteredAppointments = mergedAppointments.filter(
        (row) =>
          row.totalPrice > 0 ||
          row.totalDrivingPrice > 0 ||
          row.appointments.length > 0
      );
      //Now we have a merged array of appointments, with the isDriving property set correctly - we can now update the state
      setCustomers(
        filteredAppointments.map((row) => {
          return {
            ...row,
            foundInDinero: false,
            loadingDinero: true,
          };
        })
      );
    } catch (err) {
      console.log("error", err);
    }

    setLoading(false);
  }, []); // Pass an empty array as the dependencies to useCallback

  useEffect(() => {
    if (props.activeTab === "overview") {
      fetchAppointments();
    }
  }, [props.activeTab, fetchAppointments]); // Now fetchAppointments is memoized and won't cause useEffect to run repeatedly

  const dineroContactExists = useCallback(
    async (dineroNo: string) => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/dinero/${props.dineroOrg}/contact/${dineroNo}`,
          { withCredentials: true }
        );
        if (res.data.ContactGuid === dineroNo) {
          return { exists: true, dineroName: res.data.Name };
        } else {
          return { exists: false, dineroName: "" };
        }
      } catch (err) {
        console.log("error", err);
        return { exists: false, dineroName: "" };
      }
    },
    [props.dineroOrg]
  ); // memoizing dineroContactExists
  //function to lookup and verify that the contact is linked and exists in dinero based on the dineroNo
  const checkDineroContacts = useCallback(async () => {
    const updatedCustomers = await Promise.all(
      customers.map(async (row) => {
        if (!row.loadingDinero) {
          // Skip customers that have already been checked
          return row;
        }
        const resp = await dineroContactExists(row.dineroNo);
        return {
          ...row,
          foundInDinero: resp.exists,
          dineroName: resp.exists ? resp.dineroName : "",
          dineroNo: resp.exists ? row.dineroNo : "",
          loadingDinero: false,
        };
      })
    );
    setCustomers(updatedCustomers);
  }, [customers, dineroContactExists]); // adding dineroContactExists as dependency

  useEffect(() => {
    checkDineroContacts();
  }, [checkDineroContacts]); // checkDineroContacts is now a dependency of this effect

  //Create a function that will be called when the user clicks the "Next" button, this will fill out the invoicingData object, and do all necessary calculations
  const calculateInvoicingData = () => {
    //Locate each unique associate inside the customers array's appointments
    const uniqueAssociates: { associateId: number; name: string }[] = [];
    customers.forEach((customer) => {
      customer.appointments.forEach((appointment) => {
        const existingAssociate = uniqueAssociates.find(
          (row) => row.associateId === appointment.associateId
        );
        if (!existingAssociate) {
          uniqueAssociates.push({
            associateId: appointment.associateId,
            name: appointment.associateName,
          });
        }
      });
    });

    const theData: invoicingData = {
      CustomerOverview: customers,
      allStatistics: {
        //Calculate statistics for each unique customer
        customerStatistics: customers.map((row: CustomerOverview) => {
          return {
            name: row.contactName,
            statistics: {
              appointments: row.appointments.length,
              drivingDistance: row.appointments.reduce(
                (sum, appointment) =>
                  sum +
                  (appointment.drivingDistance && appointment.isDriving
                    ? appointment.drivingDistance
                    : 0),
                0
              ),
              drivingPrice: row.totalDrivingPrice,
              totalPrice: row.totalPrice,
              //Calculate only if isDriving is false
              effectiveAverageHourlyPrice:
                row.appointments.reduce(
                  (sum, appointment) =>
                    sum +
                    (!appointment.isDriving
                      ? appointment.effectiveHourlyPrice
                      : 0),
                  0
                ) /
                row.appointments.filter((appointment) => !appointment.isDriving)
                  .length,

              duration: row.appointments.reduce(
                (sum, appointment) =>
                  sum + (!appointment.isDriving ? appointment.duration : 0),
                0
              ),
            },
          };
        }),
        //Calculate statistics for all associates, based on the appointments in the customers array, for each unique associateId
        userStatistics: uniqueAssociates.map((row) => {
          return {
            associateId: row.associateId,
            name: row.name,
            statistics: {
              appointments: customers.reduce(
                (sum, customer) =>
                  sum +
                  customer.appointments.filter(
                    (appointment) => appointment.associateId === row.associateId
                  ).length,
                0
              ),
              //Calculate driving distance for all customers, only if isDriving is true
              drivingDistance: customers.reduce(
                (sum, customer) =>
                  sum +
                  customer.appointments.reduce(
                    (sum, appointment) =>
                      sum +
                      (appointment.associateId === row.associateId &&
                      appointment.isDriving
                        ? appointment.drivingDistance
                          ? appointment.drivingDistance
                          : 0
                        : 0),
                    0
                  ),
                0
              ),
              drivingPrice:
                customers.reduce(
                  (sum, customer) =>
                    sum +
                    customer.appointments.reduce(
                      (sum, appointment) =>
                        sum +
                        (appointment.associateId === row.associateId &&
                        appointment.isDriving
                          ? appointment.drivingPrice
                            ? appointment.drivingPrice
                            : 0
                          : 0),
                      0
                    ),
                  0
                ) *
                customers.reduce(
                  (sum, customer) =>
                    sum +
                    customer.appointments.reduce(
                      (sum, appointment) =>
                        sum +
                        (appointment.associateId === row.associateId &&
                        appointment.isDriving
                          ? appointment.drivingDistance
                            ? appointment.drivingDistance
                            : 0
                          : 0),
                      0
                    ),
                  0
                ),
              //Both driving and non-driving
              totalPrice: customers.reduce(
                (sum, customer) =>
                  sum +
                  customer.appointments.reduce(
                    (sum, appointment) =>
                      sum +
                      (appointment.associateId === row.associateId
                        ? appointment.totalPrice +
                          (appointment.isDriving
                            ? appointment.drivingPrice *
                              appointment.drivingDistance
                            : 0)
                        : 0),
                    0
                  ),
                0
              ),
              effectiveAverageHourlyPrice:
                customers.reduce(
                  (sum, customer) =>
                    sum +
                    customer.appointments.reduce(
                      (sum, appointment) =>
                        sum +
                        (appointment.associateId === row.associateId &&
                        !appointment.isDriving
                          ? appointment.effectiveHourlyPrice
                          : 0),
                      0
                    ),
                  0
                ) /
                customers.reduce(
                  (sum, customer) =>
                    sum +
                    customer.appointments.filter(
                      (appointment) =>
                        appointment.associateId === row.associateId
                    ).length,
                  0
                ),
              duration: customers.reduce(
                (sum, customer) =>
                  sum +
                  customer.appointments.reduce(
                    (sum, appointment) =>
                      sum +
                      (appointment.associateId === row.associateId
                        ? !appointment.isDriving
                          ? appointment.duration
                          : 0
                        : 0),
                    0
                  ),
                0
              ),
            },
          };
        }),
        totalStatistics: {
          appointments: customers.reduce(
            (sum, customer) => sum + customer.appointments.length,
            0
          ),
          //Calculate driving distance for all customers, only if isDriving is true
          drivingDistance: customers.reduce(
            (sum, customer) =>
              sum +
              customer.appointments.reduce(
                (sum, appointment) =>
                  sum +
                  (appointment.isDriving ? appointment.drivingDistance : 0),
                0
              ),
            0
          ),
          drivingPrice: customers.reduce(
            (sum, customer) =>
              sum +
              customer.appointments.reduce(
                (sum, appointment) =>
                  sum +
                  (appointment.isDriving
                    ? appointment.drivingPrice * appointment.drivingDistance
                    : 0),
                0
              ),
            0
          ),
          totalPrice: customers.reduce(
            (sum, customer) =>
              sum +
              customer.appointments.reduce(
                (sum, appointment) =>
                  sum +
                  appointment.totalPrice +
                  (appointment.isDriving
                    ? appointment.drivingPrice * appointment.drivingDistance
                    : 0),
                0
              ),
            0
          ),
          //Calculate only if isDriving is false
          effectiveAverageHourlyPrice:
            customers.reduce(
              (sum, customer) =>
                sum +
                customer.appointments.reduce(
                  (sum, appointment) =>
                    sum +
                    (!appointment.isDriving
                      ? appointment.effectiveHourlyPrice
                      : 0),
                  0
                ),
              0
            ) /
            customers.reduce(
              (sum, customer) =>
                sum +
                //Only if the appointment is not driving
                customer.appointments.filter(
                  (appointment) => !appointment.isDriving
                ).length,
              0
            ),
          duration: customers.reduce(
            (sum, customer) =>
              sum +
              customer.appointments.reduce(
                (sum, appointment) =>
                  sum + (!appointment.isDriving ? appointment.duration : 0),
                0
              ),
            0
          ),
        },
      },
    };
    console.log("theData", theData);
    props.setInvoicingData(theData);
  };

  return (
    <>
      <MDBRow>
        <MDBCol md="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle className="mb-4">
                Customers with upcoming invoice
                <span className="float-end">
                  <MDBBtn
                    color="secondary"
                    floating
                    tag="a"
                    onClick={() => {
                      fetchAppointments();
                    }}
                  >
                    <MDBIcon fas icon="sync" />
                  </MDBBtn>
                </span>
              </MDBCardTitle>
              <MDBCardText>
                {!loading && customers.length === 0 && (
                  <span>No upcoming invoices yet!</span>
                )}

                {!loading && customers.length > 0 && (
                  <MDBAccordion>
                    {customers.map((row: CustomerOverview) => {
                      return (
                        <MDBAccordionItem
                          headerTitle={
                            <>
                              <span className="text-truncate">
                                {row.contactName}
                              </span>{" "}
                              {row.loadingDinero && (
                                <MDBSpinner className="ms-2" size="sm">
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </MDBSpinner>
                              )}
                              {row.foundInDinero && !row.loadingDinero && (
                                <MDBBadge
                                  onClick={(event) => {
                                    event.stopPropagation(); //From stopping the accordion from opening
                                    //Set the active contact
                                    setActiveContact({
                                      contactId: row.contactId,
                                      dineroNo: row.dineroNo,
                                    });
                                    //Show the modal
                                    setShowCreateDineroContact(true);
                                  }}
                                  color="success"
                                  className="ms-2"
                                >
                                  Found{" "}
                                  <span className="d-none d-md-inline">
                                    in Dinero: ({row.dineroName})
                                  </span>
                                </MDBBadge>
                              )}
                              {!row.foundInDinero && !row.loadingDinero && (
                                <MDBBadge
                                  onClick={(event) => {
                                    event.stopPropagation(); //From stopping the accordion from opening
                                    //Set the active contact
                                    setActiveContact({
                                      contactId: row.contactId,
                                      dineroNo: row.dineroNo,
                                    });
                                    //Show the modal
                                    setShowCreateDineroContact(true);
                                  }}
                                  className="ms-2 me-2"
                                  color="danger"
                                >
                                  Connect
                                  <span className="d-none d-lg-inline">
                                    {" "}
                                    to Dinero before transferring
                                  </span>
                                </MDBBadge>
                              )}
                              <span
                                className="d-none d-md-inline"
                                style={{ position: "absolute", right: "50px" }}
                              >
                                {row.totalPrice}{" "}
                                {settings.selectedCurrency.iso_code}{" "}
                                {row.totalDrivingPrice > 0 && (
                                  <>
                                    (
                                    <span className="text-primary">
                                      <MDBIcon icon="car" />{" "}
                                      {row.totalDrivingPrice}{" "}
                                      {settings.selectedCurrency.iso_code}
                                    </span>
                                    )
                                  </>
                                )}
                              </span>
                            </>
                          }
                          collapseId={row.contactId}
                          key={row.contactId}
                        >
                          <MDBDatatable
                            striped
                            bordered
                            hover
                            data={{
                              columns: [
                                "Type",
                                "Start",
                                "End",
                                "Subject",
                                "Quantity",
                                "Price",
                                "Actions",
                              ],
                              rows: row.appointments.map(
                                (appointment: Appointment) => {
                                  const startDate: Date = new Date(
                                    appointment.date
                                  );
                                  const endDate: Date = new Date(
                                    appointment.endDate
                                  );
                                  return [
                                    appointment.isDriving ? (
                                      <MDBIcon
                                        icon="car"
                                        className="text-primary"
                                      />
                                    ) : (
                                      <MDBIcon
                                        icon="clipboard-list"
                                        className="text-primary"
                                      />
                                    ),
                                    //format datetime to a readable format
                                    startDate.toLocaleString(),
                                    endDate.toLocaleString(),
                                    appointment.title,
                                    appointment.isDriving
                                      ? (appointment.drivingDistance
                                          ? appointment.drivingDistance
                                          : 0) +
                                        " " +
                                        unitTypeArray.find(
                                          (unit) =>
                                            unit.value ===
                                            settings.selectedUnitType2
                                        )?.text
                                      : appointment.duration +
                                        " " +
                                        unitTypeArray.find(
                                          (unit) =>
                                            unit.value ===
                                            settings.selectedUnitType1
                                        )?.text,
                                    appointment.isDriving
                                      ? (appointment.drivingPrice
                                          ? appointment.drivingPrice
                                          : 0) *
                                        (appointment.drivingDistance
                                          ? appointment.drivingDistance
                                          : 0)
                                      : appointment.totalPrice,
                                    //Edit button
                                    <MDBBtn
                                      size="sm"
                                      floating
                                      className="message-btn ms-2"
                                      onClick={() => {
                                        setActiveAppointment(appointment);
                                        setShowChangeAppointment(true);
                                      }}
                                    >
                                      <MDBIcon icon="pen" />
                                    </MDBBtn>,
                                  ];
                                }
                              ),
                            }}
                          />
                          <div
                                className="d-md-none mt-2"
                              >
                                <b>Total for this group: </b>
                                {row.totalPrice}{" "}
                                {settings.selectedCurrency.iso_code}{" "}
                                {row.totalDrivingPrice > 0 && (
                                  <>
                                    (
                                    <span className="text-primary">
                                      <MDBIcon icon="car" />{" "}
                                      {row.totalDrivingPrice}{" "}
                                      {settings.selectedCurrency.iso_code}
                                    </span>
                                    )
                                  </>
                                )}
                              </div>
                        </MDBAccordionItem>
                      );
                    })}
                  </MDBAccordion>
                )}
                {loading && (
                  <MDBSpinner role="status">
                    <span className="visually-hidden">Loading...</span>
                  </MDBSpinner>
                )}
                {/* Row that shows the total to the right */}
                <MDBRow className="mt-3">
                  <MDBCol size={12} className="text-end">
                    <span className="fw-bold">Total:</span>{" "}
                    {customers.reduce((sum, row) => {
                      return sum + row.totalPrice;
                    }, 0)}{" "}
                    {settings.selectedCurrency.iso_code} (
                    <span className="text-primary">
                      <MDBIcon icon="car" />{" "}
                      {customers.reduce((sum, row) => {
                        return sum + row.totalDrivingPrice;
                      }, 0)}{" "}
                      {settings.selectedCurrency.iso_code}
                    </span>
                    )
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mt-3">
                  <MDBCol className="text-end" size={12}>
                    <MDBBtn
                      disabled={
                        customers.filter((row) => row.foundInDinero).length ===
                        0
                      }
                      color="primary"
                      onClick={() => {
                        calculateInvoicingData();
                        props.changeTab("transfer");
                      }}
                    >
                      Next <MDBIcon icon="arrow-right" className="ms-2" />
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <ChangeAppointment
        showModal={showChangeAppointment}
        setShowModal={setShowChangeAppointment}
        refreshAppointments={fetchAppointments}
        appointment={activeAppointment}
      />
      <CreateDineroContact
        showModal={showCreateDineroContact}
        setShowModal={setShowCreateDineroContact}
        refreshAppointments={fetchAppointments}
        contactId={activeContact.contactId}
        dineroNo={activeContact.dineroNo}
        dineroOrgNo={props.dineroOrg}
      />
    </>
  );
};

export default OverviewTab;
