import React, { useCallback, useEffect } from "react";

//Modal using MDBootstrap, that allows a person to change consultant, text, factor for the appointment

import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn,
  MDBModalDialog,
  MDBModalContent,
  MDBAutocomplete,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { useState } from "react";
import axios from "axios";
import { contact } from "../../../types/contact";
import CreateDineroContactForm from "./Form";

interface IChangeAppointmentProps {
  //Is the modal open
  showModal: boolean;
  //Function to close the modal
  setShowModal: (showModal: boolean) => void;
  //Function to refresh the appointments
  refreshAppointments: () => void;
  //The appointment to change
  contactId: number;
  dineroNo: string;
  dineroOrgNo: number;
}

const CreateDineroContact = (props: IChangeAppointmentProps) => {
  //State for the saved dinero contact guid
  const [dineroContactGuid, setDineroContactGuid] = useState<string>(
    props.dineroNo
  );
  const [dineroContactName, setDineroContactName] = useState<string>("");
  //State for the name in superoffice
  const [superofficeContact, setSuperofficeContact] = useState<contact | null>(
    null
  );
  //state for creating a new contact
  const [createNewContact, setCreateNewContact] = useState<boolean>(false);
  //State for the dropdown loading animation
  const [loading, setLoading] = useState<boolean>(false);
  //State for the error message
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [asyncData, setAsyncData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //useeffect to update state from props
  useEffect(() => {
    setDineroContactGuid(props.dineroNo);
    setDineroContactName("");
  }, [props.dineroNo]); // fetchContactName is now a dependency of this effect

  //UseEffect that will call the same endpoint as the autocomplete, to get the name of the contact, but using ContactGuid as
  //parameter instead of term
  const fetchContactName = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/dinero/${props.dineroOrgNo}/contact/${dineroContactGuid}`,
        { withCredentials: true }
      );
      setDineroContactName(res.data.Name);
    } catch (err: any) {
      console.log(err);
    }
    setLoading(false);
  }, [dineroContactGuid, props.dineroOrgNo]); // dependencies of fetchContactName

  useEffect(() => {
    console.log(dineroContactGuid);
    if (dineroContactGuid !== "") {
      fetchContactName();
    }
  }, [dineroContactGuid, dineroContactName, fetchContactName]); // fetchContactName is now a dependency of this effect

  const fetchContact = useCallback(async () => {
    if (props.contactId > 0) {
      setLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/superoffice/contact/${props.contactId}`,
          { withCredentials: true }
        );
        setSuperofficeContact(res.data);
      } catch (err: any) {
        console.log(err);
      }
      setLoading(false);
    }
  }, [props.contactId]); // dependencies of fetchContact

  useEffect(() => {
    fetchContact();
  }, [fetchContact]); // fetchContact is now a dependency of this effect

  const onAsyncSearch = async (query: string) => {
    setIsLoading(true);

    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/dinero/${props.dineroOrgNo}/contact?term=${query}`,
      { withCredentials: true }
    );
    const data = res.data.map((item: any) => ({
      name: item.Name,
      id: item.ContactGuid,
    }));

    await setIsLoading(false);

    setAsyncData(data);
  };

  const updateContact = async () => {
    try {
      setLoading(true);
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/superoffice/contact/${props.contactId}`,
        {
          dineroNo: dineroContactGuid,
        },
        { withCredentials: true }
      );
      if (res.status === 200) {
        props.refreshAppointments();
        //reset modal
        setDineroContactGuid("");
        setDineroContactName("");
        props.setShowModal(false);
      }
    } catch (err: any) {
      console.log(err);
      setErrorMessage(err.response.data.message);
    }
    setLoading(false);
  };
  //Remove assignment
  const removeAssignement = async () => {
    try {
      setLoading(true);
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/superoffice/contact/${props.contactId}`,
        {
          dineroNo: "",
        },
        { withCredentials: true }
      );
      if (res.status === 200) {
        props.refreshAppointments();
        props.setShowModal(false);
      }
    } catch (err: any) {
      console.log(err);
      setErrorMessage(err.response.data.message);
    }
    setLoading(false);
  };

  return (
    <MDBModal open={props.showModal} setOpen={props.setShowModal} tabIndex={-1}>
      <MDBModalDialog size="lg">
        <MDBModalContent>
          <MDBModalHeader>Assign contact in Dinero</MDBModalHeader>

          {loading && (
            <MDBModalBody>
              <div className="d-flex justify-content-center mt-3">
                <MDBSpinner role="status">
                  <span className="visually-hidden">Loading...</span>
                </MDBSpinner>
              </div>
            </MDBModalBody>
          )}
          {!loading && (
            <>
              {dineroContactGuid === "" && (
                <>
                  {createNewContact && (
                    <CreateDineroContactForm
                      dineroOrgNo={props.dineroOrgNo}
                      setDineroContactGuid={setDineroContactGuid}
                      setDineroContactName={setDineroContactName}
                      superofficeContact={superofficeContact}
                      setLoading={setLoading}
                      setCreateNewContact={setCreateNewContact}
                    />
                  )}
                  {!createNewContact && (
                    <>
                      <MDBModalBody>
                        <MDBAutocomplete
                          data={asyncData}
                          isLoading={isLoading}
                          label="Search contact in Dinero"
                          displayValue={(row: any) => row.id}
                          itemContent={(row: any) => (
                            <div className="autocomplete-custom-item-content">
                              <div className="autocomplete-custom-item-title">
                                {row.name}
                              </div>
                            </div>
                          )}
                          onSelect={(value: any) => {
                            const consultant: any = asyncData.find(
                              (item: any) => item.id === value
                            );
                            console.log(consultant);
                            setDineroContactGuid(consultant?.id);
                          }}
                          onSearch={onAsyncSearch}
                        />
                        {/* button for creating a new customer */}
                        <MDBBtn
                          color="primary"
                          className="mt-3"
                          onClick={() => setCreateNewContact(true)}
                        >
                          Create new contact
                        </MDBBtn>
                      </MDBModalBody>
                    </>
                  )}
                </>
              )}
              {dineroContactGuid !== "" && (
                <>
                  <MDBModalBody>
                    <h2>{superofficeContact?.name}</h2>
                    <span className="text-center d-block">
                      Will be joined with
                    </span>
                    <h2>{dineroContactName}</h2>
                    {/* Button to change assignment */}
                    <MDBBtn
                      color="primary"
                      onClick={() => setDineroContactGuid("")}
                    >
                      Change assignment
                    </MDBBtn>
                    <MDBBtn
                      color="primary"
                      className="ms-2"
                      onClick={() => {
                        removeAssignement();
                      }}
                    >
                      Remove assignment
                    </MDBBtn>
                  </MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn
                      color="secondary"
                      onClick={() => props.setShowModal(false)}
                    >
                      Close
                    </MDBBtn>
                    <MDBBtn
                      color="primary"
                      onClick={() => updateContact()}
                      disabled={loading || dineroContactGuid === ""}
                    >
                      Save
                    </MDBBtn>
                  </MDBModalFooter>
                </>
              )}
              {errorMessage !== "" && <p>{errorMessage}</p>}
            </>
          )}
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};
export default CreateDineroContact;
