import React, {useState} from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import OverviewElement from "./overviewElement";
import { Associate } from "../../../types/associate";
import axios from "axios";
import { getSettings } from "../../../utils/settings";
import { CarAllowance, groupedDrivingTasks } from "../../../types/carAllowance";
import { Appointment } from "../../../types/appointment";
import ProgressModal from "../../modals/ProgressModal";
import SuccessModal from "../../modals/SuccessModal";

//Interface for the props

interface overviewProps {
  totalKm: number;
  drivingUnit: string;
  currency: string;
  totalDrivingValue: number;
  totalDrivingDuration: number;
  ownTurnover: number;
  teamTurnover: number;
  fullTurnover: number;
  associate: Associate;
  groupedDrivingTasks: groupedDrivingTasks[];
}

//This is a component that contains an overview of all the data that will be transferred
//to the salary system, including driving as well as bonuses
//Add 3 columns for the first row, with the following columns:
//1. Total KM
//2. Total driving value
//3. Total driving duration
//Then on the next row, add the following columns:
//1. Own turnover
//2. Company turnover
//3. Team turnover
//Then on the last row, add 1 column with the total bonus value

const Overview =  (props: overviewProps) => {
  let theTurnover: number = 0;
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);


  const transferCarAllowanceToSalarySystem = () : Promise<any>[] => {
 

    const body: CarAllowance[] = props.groupedDrivingTasks
      .map((task: groupedDrivingTasks) => {
        const date = task.date;
        return task.drivingTasks.map((drivingTask: Appointment) => {
          const carAllowance: CarAllowance = {
            approved: true,
            date: date,
            kilometers: drivingTask.drivingDistance,
            avoidFerries: false,
            employeeID: props.associate.salaryPersonId,
            licensePlate: props.associate.licensePlate,
            reason: drivingTask.text ?? "Ingen beskrivelse",
            locationFrom: drivingTask.startAddress,
            locationTo: drivingTask.endAddress,
            orgAppointment: drivingTask,
          };
          return carAllowance;
        });
      })
      .flat();
    console.log(body);

    //Create a promise array, cause the endpoint does not support bulk insert
    const promiseArray: Promise<any>[] = [];

    //Loop through all the car allowances, and create a promise for each
    body.forEach((carAllowance: CarAllowance) => {
      //remove the orgAppointment, from body, cause it is not needed in the backend
      //First copy it, so that we can use it in the callback
      const orgAppointment: Appointment =
        carAllowance.orgAppointment as Appointment;
      delete carAllowance.orgAppointment;
      const promise = new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/salary/carAllowance`,
            carAllowance,
            { withCredentials: true }
          )
          .then((res: any) => {
            //After the car allowance is inserted, we need to update the orgAppointment, so that it is marked as transferred
            axios
              .put(
                `${process.env.REACT_APP_API_URL}/api/superoffice/appointment/${orgAppointment.appointmentId}/ignore?drivingId=${res.data.data.id}`,
                { withCredentials: true }
              )
              .then((res: any) => {
                resolve(res);
              })
              .catch((err: any) => {
                reject(err);
              });
          }).catch((err: any) => {
            reject(err);
          });
      });
      promiseArray.push(promise);
    });
    return promiseArray;
  };

  const transferBonusToSalarySystem = (bonus: number, turnover: number) : Promise<any> => {
    const promise = new Promise((resolve, reject) => {
    const settings = getSettings();
    const body = {
      amount: bonus,
      title: settings.bonusText
        .replace("{turnover}", turnover.toString())
        .replace(
          "{month}",
          new Date().toLocaleString("default", { month: "long" })
        ),
      type: settings.selectedBonusType,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/salary/oneTimePayment/${props.associate.salaryPersonId}`,
        body,
        { withCredentials: true }
      )
      .then((res: any) => {
        console.log(res);
        resolve(res);
      }).catch((err: any) => {
        reject(err);
      } );
    });
    return promise;
  };
  
  const handleTransferClick = async (bonus: number, turnover: number)  => {
    //Reset progress
    setProgress(0);

    //Show progress modal
    setShowProgress(true);
    
    //First transfer car allowance
    const PromiseArray = transferCarAllowanceToSalarySystem();
    //Then transfer bonus
    const bonusPromise = transferBonusToSalarySystem(bonus, turnover);
    //Add to promise array
    PromiseArray.push(bonusPromise);
    //Calculate the progress step
    
    const progressStep = 100 / PromiseArray.length;
    //Loop through all promises, and update the progress
    PromiseArray.forEach((promise: Promise<any>) => {
      promise.then((res: any) => {
        setProgress(progress + progressStep);
      }).catch((err: any) => {
        console.log(err);  
      });
    });
    //When all promises are done, show success modal
    Promise.all(PromiseArray).then((res: any) => {
      setShowProgress(false);
      setShowSuccess(true);
    }).catch((err: any) => {
      console.log(err);
      alert("An error occured while transferring data to salary system");
      setShowProgress(false);
    });
  }
    


  theTurnover =
    (props.associate.bonusType == "full"
      ? props.associate.bonusLimit - props.fullTurnover
      : props.associate.bonusType == "team"
      ? props.associate.bonusLimit - props.teamTurnover
      : props.associate.bonusType == "own"
      ? props.associate.bonusLimit - props.ownTurnover
      : 0) * -1;
  let bonus: number = theTurnover * (props.associate.bonusPercentage / 100);
  bonus = bonus < 0 ? 0 : bonus;
  return (
    <>
      <MDBRow className="text-center px-5 mt-2">
        <MDBCol size={4}>
          <OverviewElement
            title="Total KM"
            value={props.totalKm.toFixed(2).toString()}
            unit={props.drivingUnit}
          />
        </MDBCol>
        <MDBCol size={4}>
          <OverviewElement
            title="Total driving value"
            value={props.totalDrivingValue.toFixed(2).toString()}
            unit={props.currency}
          />
        </MDBCol>
        <MDBCol size={4}>
          <OverviewElement
            title="Total driving duration"
            value={props.totalDrivingDuration.toFixed(2).toString()}
            unit="Hours"
          />
        </MDBCol>
      </MDBRow>

      <MDBRow className="text-center px-5 mt-2">
        <MDBCol size={6}>
          <OverviewElement
            title="Own turnover"
            value={props.ownTurnover.toFixed(2).toString()}
            unit={props.currency}
          />
        </MDBCol>

        <MDBCol size={6}>
          <OverviewElement
            title="Team turnover"
            value={props.teamTurnover.toFixed(2).toString()}
            unit={props.currency}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow className="text-center px-5 mt-2">
        <MDBCol size={12}>
          <OverviewElement
            title="Total bonus value"
            value={bonus.toFixed(2).toString()}
            unit={props.currency}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="text-center px-5 mt-2">
        <MDBCol size={12}>
          <MDBBtn
            onClick={() => handleTransferClick(bonus, theTurnover)}
            color="success"
          >
            Transfer to salary system
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <ProgressModal
        show={showProgress}
        setShow={setShowProgress}
        title={"Transferring data to salary system"}
        progress={progress}
      />
      <SuccessModal
        btn={true}
        show={showSuccess}
        msg={"Data transferred successfully for " + props.associate.fullName}
        data={null}
        setShow={setShowSuccess}
         
      />
    </>
  );
};

export default Overview;
