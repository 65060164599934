import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBChart,
  MDBBtn,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import {
  InvoiceBody,
  ProductLine,
  SaleRequestBody,
  customerStatistics,
  invoicingData,
  settings,
  userStatistics,
} from "../../types/system";
import dinero_logo from "../../assets/dinero_logo.png";
import { getSettings } from "../../utils/settings";
import { Appointment, CustomerOverview } from "../../types/appointment";
import axios from "axios";
import ProgressModal from "../modals/ProgressModal";
import SuccessModal from "../modals/SuccessModal";

//This is a component that will show some graphs and statistics about the appointments, that is about to be
//invoice. I want to show an overview of the customers on a bar chart with the biggest turnover, a bar chart with
//Each consultant, and their respective turnovers
interface IOverviewTab {
  invoicingData: invoicingData | null;
  dineroOrg: number;
  activeTab: string;
  salaryOrg: string;
  setActiveTab: (tab: string) => void;
}

const TransferTab = (props: IOverviewTab) => {
  //State for the current % of the progress bar
  const [progress, setProgress] = useState<number>(0);
  //show progress bar modal
  const [showProgress, setShowProgress] = useState<boolean>(false);
  //state for showing the success modal
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  //State for holding guids for the invoices, based on each customer id
  const [invoiceGuids, setInvoiceGuids] = useState<{ [key: number]: string }>(
    {}
  );
  //State for the settings
  const [settings, setSettings] = useState<settings | null>(null);
  //useEffect that will fetch the settings, when the tab is loaded
  useEffect(() => {
    if (props.activeTab === "transfer") {
      setSettings(getSettings());
    }
  }, [props.activeTab]);

  const createInvoice = async (stepSize: number) => {
    if (props.invoicingData !== null) {
      const invData: CustomerOverview[] = props.invoicingData.CustomerOverview;

      // Initiate a mutable object to accumulate the guids
      const newInvoiceGuids: { [key: string]: string } = {};

      // Map instead of forEach to create a new array of promises
      const promises: Promise<any>[] = invData.map(
        async (customer: CustomerOverview) => {
          const invoiceBody: InvoiceBody = {
            contactGuid: customer.dineroNo,
            productLines: customer.appointments.map(
              (appointment: Appointment) => {
                const startDate: Date = new Date(
                  appointment.date
                );
                const endDate: Date = new Date(
                  appointment.endDate
                );
                //Containing the start date and time and end date and time of the appointment, the consultant and then two line breaks for the text
                const description = `${startDate.toLocaleString()} - ${endDate.toLocaleString()}\n${appointment.associateName}\nBestilt af: ${appointment.personName}\n\n${appointment.title}\n${appointment.text}`;

                const productLine: ProductLine = {
                  accountNumber: settings
                    ? appointment.isDriving
                      ? settings.selectedProduct2.accountNumber
                      : settings.selectedProduct1.accountNumber
                    : 1000,
                  baseAmountValue: appointment.isDriving
                    ? appointment.drivingPrice
                    : appointment.effectiveHourlyPrice,
                  comments: description,
                  productGuid: settings
                    ? appointment.isDriving
                      ? settings.selectedProduct2.guid
                      : settings.selectedProduct1.guid
                    : "",
                  quantity: appointment.isDriving
                    ? appointment.drivingDistance
                    : appointment.duration,
                  unit: settings
                    ? appointment.isDriving
                      ? settings.selectedUnitType2
                      : settings.selectedUnitType1
                    : "hours",
                };
                return productLine;
              }
            ),
            currency: settings ? settings.selectedCurrency.iso_code : "DKK",
          };

          // Return a new Promise that resolves with customer.contactId and the response guid
          return axios
            .post(
              `${process.env.REACT_APP_API_URL}/api/dinero/${props.dineroOrg}/invoice`,
              invoiceBody,
              { withCredentials: true }
            )
            .then((res) => {
              console.log("Invoice created", res.data);

              //Update the progress bar
              setProgress((prevProgress) => prevProgress + stepSize);

              // Accumulate the guids without setting state
              newInvoiceGuids[customer.contactId] = res.data.Guid;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      );

      //Wait for all the invoices to be created
      await Promise.all(promises);

      // Update state once with all the guids
      setInvoiceGuids(newInvoiceGuids);
      console.log("All invoices created", newInvoiceGuids);
    }
  };

  const createSale = async (stepSize: number) => {
    if (props.invoicingData !== null) {
      const invData: CustomerOverview[] = props.invoicingData.CustomerOverview;
      const associates: number[] =
        props.invoicingData.allStatistics.userStatistics.map(
          (user: userStatistics) => user.associateId
        );
      //Promise array for the sales
      const promises: Promise<any>[] = [];
      //Map each associate to a sale
      associates.forEach(async (associateId: number) => {
        //Find each uniques customer, that has appointments with the associate attached in the customers appointments array
        const customers = invData.filter((customer: CustomerOverview) =>
          customer.appointments.some(
            (appointment: Appointment) =>
              appointment.associateId === associateId
          )
        );
        //Now for each customer for each associate, calculate the total price, and create a sale
        customers.forEach(async (customer: CustomerOverview) => {
          //Array of appointment ids
          const appointmentIds: number[] = customer.appointments
            .filter(
              (appointment: Appointment) =>
                appointment.associateId === associateId
            )
            .map((appointment: Appointment) => appointment.appointmentId);
          //Calculate the total price
          const totalPrice = customer.appointments.reduce(
            (acc: number, appointment: Appointment) => {
              //Make sure the appointment is owned by the associate
              if (appointment.associateId !== associateId) {
                return acc;
              }
              return (
                acc +
                (appointment.isDriving
                  ? appointment.drivingPrice * appointment.drivingDistance
                  : appointment.duration * appointment.effectiveHourlyPrice)
              );
            },
            0
          );
          //Create the sale
          const sale: SaleRequestBody = {
            invoice: invoiceGuids[customer.contactId],
            heading: `Invoice for ${customer.contactName}`,
            associateId: associateId,
            amount: totalPrice,
            personId: 0,
            contactId: customer.contactId,
            currencyId: settings ? settings.selectedCurrency.currencyId : 0,
            saleTypeId: settings
              ? settings.selectedSaleType
                ? settings.selectedSaleType
                : 0
              : 0,
            appointmentIds: appointmentIds,
          };

          //Post the sale to superoffice in the backend, by adding the promise to the array
          promises.push(
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/api/superoffice/sale`,
                sale,
                { withCredentials: true }
              )
              .then((res) => {
                //Update the progress bar
                setProgress((prevProgress) => prevProgress + stepSize);
              })
              .catch((err) => {
                console.log(err);
              })
          );
        });
      });
      //Wait for all the sales to be created
      await Promise.all(promises);
    }
  };

  //Function that performs the creation of invoices and sales, and updates the progress bar
  const transfer = async () => {
    if (props.invoicingData) {
      setProgress(0);
      setShowProgress(true);

      //Calculate the total number of steps
      let steps = props.invoicingData.CustomerOverview.length;
      const associates: number[] =
        props.invoicingData.allStatistics.userStatistics.map(
          (user: userStatistics) => user.associateId
        );
      //Map each associate to a sale
      associates.forEach(async (associateId: number) => {
        if (props.invoicingData) {
          //Find each uniques customer, that has appointments with the associate attached in the customers appointments array
          const customers = props.invoicingData.CustomerOverview.filter(
            (customer: CustomerOverview) =>
              customer.appointments.some(
                (appointment: Appointment) =>
                  appointment.associateId === associateId
              )
          );
          steps += customers.length;
        }
      });

      //Figure out the step size
      const stepSize = 100 / steps;
      //Create the invoices
      await createInvoice(stepSize);
      //Create the sales
      await createSale(stepSize);
      //Reset the progress bar
      setShowProgress(false);
      setShowSuccess(true);
    }
  };

  const drivingData = {
    labels: props.invoicingData
      ? props.invoicingData.allStatistics.userStatistics.map(
          (user: userStatistics) => user.name
        )
      : [],
    datasets: [
      {
        label: "Km driven",
        data: props.invoicingData
          ? props.invoicingData?.allStatistics.userStatistics.map(
              (user: userStatistics) => user.statistics.drivingDistance
            )
          : [],
        //Randomly generate colors for each associate
        backgroundColor: props.invoicingData?.allStatistics.userStatistics.map(
          () => `#${Math.floor(Math.random() * 16777215).toString(16)}`
        ),
      },
    ],
  };

  const drivingOptions = {
    plugins: {
      datalabels: {
        formatter: (value: any) => {
          let sum = 0;
          // Assign the data to the variable and format it according to your needs
          const dataArr = drivingData.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
            return sum;
          });
          const percentage = ((value * 100) / sum).toFixed(2) + "%";
          return percentage;
        },
        color: "white",
        labels: {
          title: {
            font: {
              size: "14",
            },
          },
        },
      },
    },
  };

  return (
    <>
      <MDBRow>
        <MDBCol md="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle className="mb-4">Review and transfer</MDBCardTitle>
              <MDBCardText>
                <MDBRow>
                  <MDBCol size={6}>
                    <MDBChart
                      type="bar"
                      data={{
                        labels:
                          props.invoicingData?.allStatistics.customerStatistics.map(
                            (customer: customerStatistics) => customer.name
                          ),
                        datasets: [
                          {
                            label: "Turnover",
                            data: props.invoicingData?.allStatistics.customerStatistics.map(
                              (customer: customerStatistics) =>
                                customer.statistics.totalPrice
                            ),
                          },
                        ],
                      }}
                    />
                  </MDBCol>
                  <MDBCol size={6}>
                    <MDBChart
                      type="bar"
                      data={{
                        labels:
                          props.invoicingData?.allStatistics.userStatistics.map(
                            (user: userStatistics) => user.name
                          ),
                        datasets: [
                          {
                            label: "Turnover",
                            yAxisID: "y",
                            data: props.invoicingData?.allStatistics.userStatistics.map(
                              (user: userStatistics) =>
                                user.statistics.totalPrice
                            ),
                            type: "bar",
                            backgroundColor: "#94DFD7",
                          },
                          {
                            label: "Effective hourly price",
                            yAxisID: "y2",
                            data: props.invoicingData?.allStatistics.userStatistics.map(
                              (user: userStatistics) =>
                                user.statistics.effectiveAverageHourlyPrice
                            ),
                            type: "line",
                            order: 0,
                            backgroundColor: "rgba(66, 133, 244, 0.0)",
                            borderColor: "#94DFD7",
                            borderWidth: 2,
                            pointBorderColor: "#94DFD7",
                            pointBackgroundColor: "#94DFD7",
                            lineTension: 0.0,
                          },
                        ],
                      }}
                      options={{
                        scales: {
                          y1: {
                            stacked: false,
                            position: "left",
                            grid: {
                              drawOnChartArea: false,
                              drawBorder: false,
                              drawTicks: false,
                            },
                            ticks: {
                              display: false,
                            },
                          },
                          y2: {
                            stacked: false,
                            position: "right",
                            grid: {
                              drawOnChartArea: false,
                            },
                            ticks: {
                              beginAtZero: true,
                            },
                          },
                        },
                      }}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mt-3">
                  <MDBCol size={6}>
                    <MDBCard>
                      <MDBCardTitle className="mb-4 mt-2">
                        KM's driven
                      </MDBCardTitle>
                      <MDBCardText>
                        <h1>
                          {props.invoicingData?.allStatistics.totalStatistics.drivingDistance.toFixed(
                            2
                          )}{" "}
                          KM
                        </h1>
                      </MDBCardText>
                    </MDBCard>
                  </MDBCol>
                  <MDBCol size={6}>
                    <MDBCard>
                      <MDBCardTitle className="mb-4 mt-2">
                        Driving turnover
                      </MDBCardTitle>
                      <MDBCardText>
                        <h1>
                          {props.invoicingData?.allStatistics.totalStatistics.drivingPrice.toFixed(
                            2
                          )}{" "}
                          {settings?.selectedCurrency.iso_code}
                        </h1>
                      </MDBCardText>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>

                <MDBRow className="mt-3">
                  <MDBCol size={8}>
                    <MDBRow className="h-50">
                      <MDBCol className="align-self-center" size={6}>
                        <MDBCard>
                          <MDBCardTitle className="mb-4 mt-2">
                            Total turnover
                          </MDBCardTitle>
                          <MDBCardText>
                            <h1>
                              {props.invoicingData?.allStatistics.totalStatistics.totalPrice.toFixed(
                                2
                              )}{" "}
                              {settings?.selectedCurrency.iso_code}
                            </h1>
                          </MDBCardText>
                        </MDBCard>
                      </MDBCol>
                      <MDBCol className="align-self-center" size={6}>
                        {" "}
                        <MDBCard>
                          <MDBCardTitle className="mb-4 mt-2">
                            Total invoices
                          </MDBCardTitle>
                          <MDBCardText>
                            <h1>
                              {props.invoicingData?.CustomerOverview.length}
                            </h1>
                          </MDBCardText>
                        </MDBCard>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="h-50">
                      <MDBCol className="align-self-center" size={6}>
                        <MDBCard>
                          <MDBCardTitle className="mb-4 mt-2">
                            Total hours invoiced
                          </MDBCardTitle>
                          <MDBCardText>
                            <h1>
                              {props.invoicingData?.allStatistics.totalStatistics.duration.toFixed(
                                2
                              )}{" "}
                              Hours
                            </h1>
                          </MDBCardText>
                        </MDBCard>
                      </MDBCol>
                      <MDBCol className="align-self-center" size={6}>
                        <MDBCard>
                          <MDBCardTitle className="mb-4 mt-2">
                            Average hourly price
                          </MDBCardTitle>
                          <MDBCardText>
                            <h1>
                              {props.invoicingData?.allStatistics.totalStatistics.effectiveAverageHourlyPrice.toFixed(
                                2
                              )}{" "}
                              {settings?.selectedCurrency.iso_code}
                            </h1>
                          </MDBCardText>
                        </MDBCard>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol size={4}>
                    <MDBCard>
                      <MDBCardTitle className="mb-4 mt-2">
                        Driving distribution
                      </MDBCardTitle>
                      <MDBCardText>
                        <MDBChart
                          type="pie"
                          data={drivingData}
                          options={drivingOptions}
                          datalabels
                        />
                      </MDBCardText>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>

                <MDBRow className="mt-3">
                  <MDBCol
                    size={4}
                    offsetLg={4}
                    offsetMd={4}
                    offsetSm={4}
                    className="text-center"
                  >
                    <MDBBtn
                      color="secondary"
                      className="me-2"
                      tag={"a"}
                      onClick={transfer}
                      disabled={showProgress || showSuccess}
                    >
                      Transfer to Dinero{" "}
                      <img
                        style={{ marginTop: "-4px", marginLeft: "5px" }}
                        src={dinero_logo}
                        alt="dinero logo"
                        width="30"
                      />
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <ProgressModal
        show={showProgress}
        setShow={setShowProgress}
        title={"Creating invoices and sales"}
        progress={progress}
      />
      <SuccessModal
        btn={false}
        show={showSuccess}
        msg={"Invoices and sales created successfully"}
        data={props.invoicingData}
        setShow={setShowSuccess}
      />
    </>
  );
};

export default TransferTab;
