import { MDBCol, MDBIcon, MDBInput, MDBRow, MDBSelect } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Associate } from "../../../types/associate";
import { Employee } from "../../../types/employee";
import { SelectData } from "mdb-react-ui-kit/dist/types/pro/forms/Select/types";

//Interface for the props
interface AssociateTableRowProps {
  superofficeAssociate: Associate;
  salaryEmployee: Employee[];
  allAssociates: Associate[];
  setSuperofficeAssociate: React.Dispatch<React.SetStateAction<Associate[]>>;
}

const AssociateTableRow = (props: AssociateTableRowProps) => {
  //Refs for all the inputs
  const bonusLimitRef = React.createRef<HTMLInputElement>();
  const bonusPercentageRef = React.createRef<HTMLInputElement>();
  const licensePlateRef = React.createRef<HTMLInputElement>();
  //State for selected salary employee
  const [salaryEmployee, setSalaryEmployee] = useState<string>(
    props.superofficeAssociate.salaryPersonId
  );
  //state for bonus type
  const [bonusType, setBonusType] = useState<string>(
    props.superofficeAssociate.bonusType
  );

  //A function to update the associate object, with new information

  const updateAssociate = () => {
    console.log("Updating associate");
    //Update the associate object
    let newAssociate = props.superofficeAssociate;
    if (bonusLimitRef.current)
      newAssociate.bonusLimit = parseInt(bonusLimitRef.current.value) ?? 0;
    else newAssociate.bonusLimit = 0;
    newAssociate.bonusType = bonusType;
    if (bonusPercentageRef.current)
      newAssociate.bonusPercentage =
        parseInt(bonusPercentageRef.current.value) ?? 0;
    else newAssociate.bonusPercentage = 0;
    newAssociate.salaryPersonId = salaryEmployee;
    if (licensePlateRef.current)
      newAssociate.licensePlate = licensePlateRef.current.value;
    else newAssociate.licensePlate = "";

    //Update the associate object in the state
    //Make a copy of the allAssociates array, and update the specific associate
    let newAllAssociates = props.allAssociates;
    newAllAssociates[
      newAllAssociates.findIndex(
        (associate) => associate.associateDbId === newAssociate.associateDbId
      )
    ] = newAssociate;
    //Update the state
    props.setSuperofficeAssociate(newAllAssociates);
  };

  //UseEffect that will run the updateAssociate function when the bonus type is changed, and when the salary employee is changed
  useEffect(() => {
    updateAssociate();
  }, [bonusType, salaryEmployee]);

  return (
    <>
      <MDBRow>
        <MDBCol size="2" className="text-start text-truncate">
          <span style={{ lineHeight: "36px" }}>
            {props.superofficeAssociate.fullName}
          </span>
        </MDBCol>
        <MDBCol size="3">
          <MDBSelect
            search
            onChange={(value: any) => {
              setSalaryEmployee(value.value);
            }}
            data={[
              {
                text: "Select Salary Employee",
                value: "",
              },
              ...props.salaryEmployee.map((employee) => {
                return {
                  value: employee.id,
                  text: employee.name,
                  defaultSelected:
                    employee.id === props.superofficeAssociate.salaryPersonId
                      ? true
                      : false,
                };
              }),
            ]}
          />
        </MDBCol>
        <MDBCol size="2">
          <MDBSelect
            onChange={(value: any) => {
              setBonusType(value.value);
            }}
            data={[
              {
                value: "full",
                text: "Full Turnover",
                defaultSelected:
                  props.superofficeAssociate.bonusType === "full"
                    ? true
                    : false,
              },
              {
                value: "own",
                text: "Own turnover",
                defaultSelected:
                  props.superofficeAssociate.bonusType === "own" ? true : false,
              },
              {
                value: "team",
                text: "Team turnover",
                defaultSelected:
                  props.superofficeAssociate.bonusType === "team"
                    ? true
                    : false,
              },
            ]}
          />
        </MDBCol>
        <MDBCol size="2">
          <MDBInput
            onChange={updateAssociate}
            type="number"
            ref={bonusLimitRef}
            defaultValue={props.superofficeAssociate.bonusLimit}
          />
        </MDBCol>
        <MDBCol size="1">
          <MDBInput
            onChange={updateAssociate}
            type="number"
            ref={bonusPercentageRef}
            defaultValue={props.superofficeAssociate.bonusPercentage}
          />
        </MDBCol>
        <MDBCol size="2">
          <MDBInput
            onChange={updateAssociate}
            ref={licensePlateRef}
            type="text"
            defaultValue={props.superofficeAssociate.licensePlate}
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};
export default AssociateTableRow;
