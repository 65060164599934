import React, { useEffect } from "react";

//Modal using MDBootstrap, that allows a person to change consultant, text, factor for the appointment

import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn,
  MDBInput,
  MDBModalDialog,
  MDBModalContent,
  MDBTextArea,
  MDBAutocomplete,
  MDBRange,
} from "mdb-react-ui-kit";
import { useState } from "react";
import axios from "axios";
import { Appointment } from "../../types/appointment";

interface IChangeAppointmentProps {
  //Is the modal open
  showModal: boolean;
  //Function to close the modal
  setShowModal: (showModal: boolean) => void;
  //Function to refresh the appointments
  refreshAppointments: () => void;
  //The appointment to change
  appointment: Appointment;
}

const ChangeAppointment = (props: IChangeAppointmentProps) => {
  //refs for the inputs
  const [consultant, setConsultant] = useState<string>(
    props.appointment.associateName
  ); //The consultant that is selected
  const [consultantId, setConsultantId] = useState<number>(
    props.appointment.associateId
  ); //The consultantId that is selected
  //Array for participants
  const [participants, setParticipants] = useState<number[]>([]);
  // Use state instead of refs
  const [title, setTitle] = useState<string>(props.appointment.title);
  const [text, setText] = useState<string>(props.appointment.textHtml);
  const [factor, setFactor] = useState<number>(props.appointment.factor);
  const [drivingDistance, setDrivingDistance] = useState<number>(
    props.appointment.drivingDistance
  );
  //Make refs
  const startAddressRef = React.createRef<HTMLInputElement>();
  const endAddressRef = React.createRef<HTMLInputElement>();
  const factorRef = React.createRef<HTMLInputElement>();
  const drivingDistanceRef = React.createRef<HTMLInputElement>();

  const [startAddress, setStartAddress] = useState<string>(
    props.appointment.startAddress
  );
  const [endAddress, setEndAddress] = useState<string>(
    props.appointment.endAddress
  );

  //State for the loading animation
  const [loading, setLoading] = useState<boolean>(false);
  //State for the error message
  const [errorMessage, setErrorMessage] = useState<string>("");
  //Function to change the appointment
  const changeAppointment = async () => {
    const dataToSend = {
      title: title,
      text: text,
      factor: factor,
      drivingDistance: drivingDistance,
      startAddress: startAddress,
      endAddress: endAddress,
      associateId:
        consultantId === props.appointment.associateId ? 0 : consultantId,
    };
    setLoading(true);
    setErrorMessage("");

    console.log("updating the participants", participants);
    //Update the participants for the appointment, if the associate is changed
    if (dataToSend.associateId !== 0) {
      await axios
        .put(
          `${process.env.REACT_APP_API_URL}/api/superoffice/appointment/${props.appointment.appointmentId}/participants`,
          {
            participants: participants,
          },
          { withCredentials: true }
        )
        .then((res: any) => {})
        .catch((err) => {
          console.log("error", err);
          setErrorMessage(err.response.data);
        });
    }
    //Update the consultant for the appointment, if the participants did succeed
    if (errorMessage === "") {
      console.log("Updating the appointment", dataToSend);
      await axios
        .put(
          `${process.env.REACT_APP_API_URL}/api/superoffice/appointment/${props.appointment.appointmentId}`,
          dataToSend,
          { withCredentials: true }
        )
        .then((res: any) => {
          props.refreshAppointments();
          props.setShowModal(false);
        })
        .catch((err) => {
          console.log("error", err);
          setErrorMessage(err.response.data);
        });
    }
    setLoading(false);
  };
  //This will mark the appointment as already invoiced, and therefore it will be ignored
  const ignoreAppointment = async () => {
    setLoading(true);
    setErrorMessage("");
    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/superoffice/appointment/${props.appointment.appointmentId}/ignore`,
        {},
        { withCredentials: true }
      )
      .then((res: any) => {
        props.refreshAppointments();
        props.setShowModal(false);
      })
      .catch((err) => {
        console.log("error", err);
        setErrorMessage(err.response.data);
      });
    setLoading(false);
  };

  const [asyncData, setAsyncData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onAsyncSearch = async (query: string) => {
    setIsLoading(true);

    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/superoffice/associates?term=${query}`,
      { withCredentials: true }
    );
    const data = res.data.map((item: any) => ({
      name: item.fullName,
      id: item.associateDbId,
    }));

    await setIsLoading(false);

    setAsyncData(data);
  };

  //Update the input values when the appointment changes
  useEffect(() => {
    console.log("updating the input values");
    setTitle(props.appointment.title);
    setText(props.appointment.textHtml);
    setFactor(props.appointment.factor);
    setDrivingDistance(props.appointment.drivingDistance);
    setStartAddress(props.appointment.startAddress);
    setEndAddress(props.appointment.endAddress);
    if (factorRef.current)
      factorRef.current.value = props.appointment.factor.toString();
    if (drivingDistanceRef.current)
      drivingDistanceRef.current.value =
        props.appointment.drivingDistance.toString();
    if (startAddressRef.current)
      startAddressRef.current.value = props.appointment.startAddress;
    if (endAddressRef.current)
      endAddressRef.current.value = props.appointment.endAddress;
  }, [props.appointment]);

  //Update consultant when the appointment changes
  useEffect(() => {
    setConsultant(props.appointment.associateName);
    setConsultantId(props.appointment.associateId);
    if (props.appointment.appointmentId !== 0) {
      //Get participants for the appointment
      const fetchParticipants = async () => {
        try {
          const res = await axios.get<number[]>(
            `${process.env.REACT_APP_API_URL}/api/superoffice/appointment/${props.appointment.appointmentId}/participants`,
            { withCredentials: true }
          );
          setParticipants(
            res.data.map((item: any) => {
              return item.associateDbId;
            })
          );
        } catch (err) {
          console.log("error", err);
        }
      };

      fetchParticipants();
    }
  }, [props.appointment]);

  return (
    <MDBModal open={props.showModal} setOpen={props.setShowModal} tabIndex={-1}>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>Change appointment</MDBModalHeader>
          <MDBModalBody>
            {consultantId !== 0 && (
              <MDBBtn
                color="primary"
                onClick={() => {
                  setConsultantId(0);
                  setConsultant("");
                }}
              >
                {consultant}
              </MDBBtn>
            )}
            {consultantId === 0 && (
              <MDBAutocomplete
                data={asyncData}
                isLoading={isLoading}
                label="Search responsible consultant"
                displayValue={(row: any) => row.id}
                itemContent={(row: any) => (
                  <div className="autocomplete-custom-item-content">
                    <div className="autocomplete-custom-item-title">
                      {row.name}
                    </div>
                  </div>
                )}
                onSelect={(value: any) => {
                  const consultant: any = asyncData.find(
                    (item: any) => item.id === value
                  );
                  console.log(consultant);
                  setConsultant(consultant?.name ?? "");
                  setConsultantId(consultant?.id ?? 0);
                  //If the consultant is changed, then remove the original associate from participants
                  if (consultant?.id !== props.appointment.associateId) {
                    //Filter the participants, and remove the original associate, and the new associate if it is in the participants

                    setParticipants(
                      participants.filter(
                        (item: number) =>
                          item !== props.appointment.associateId &&
                          item !== consultant?.id
                      )
                    );
                  }
                }}
                onSearch={onAsyncSearch}
              />
            )}
            <MDBInput
              className="mt-2 active"
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <MDBTextArea
              className="mt-2 active"
              label="Text"
              value={text}
              rows={7}
              onChange={(e) => setText(e.target.value)}
            />
            {!props.appointment.isDriving && (
              <>
                <MDBRange
                  value={factor}
                  min="0"
                  max="300"
                  step="10"
                  label="Factor"
                  onChange={(e) => {
                    setFactor(Number(e.target.value));
                    if (factorRef.current)
                      factorRef.current.value = e.target.value;
                  }}
                />
                <MDBInput
                  className="mt-2 active"
                  defaultValue={factor.toString()}
                  ref={factorRef}
                  onChange={(e) => setFactor(Number(e.target.value))}
                />
              </>
            )}
            {props.appointment.isDriving && (
              <>
                <MDBInput
                  className="mt-2 active"
                  label="Km"
                  ref={drivingDistanceRef}
                  defaultValue={drivingDistance.toString()}
                  onChange={(e) => setDrivingDistance(Number(e.target.value))}
                />
                <MDBInput
                  className="mt-2 active"
                  label="Start address"
                  ref={startAddressRef}
                  defaultValue={props.appointment.startAddress}
                  onChange={(e) => setStartAddress(e.target.value)}
                />
                <MDBInput
                  className="mt-2 active"
                  label="End address"
                  ref={endAddressRef}
                  defaultValue={props.appointment.endAddress}
                  onChange={(e) => setEndAddress(e.target.value)}
                />
              </>
            )}
            {errorMessage !== "" && (
              <p className="text-danger">{errorMessage}</p>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="danger" onClick={() => ignoreAppointment()}>
              Ignore this registration
            </MDBBtn>
            <MDBBtn color="secondary" onClick={() => props.setShowModal(false)}>
              Close
            </MDBBtn>
            <MDBBtn
              color="primary"
              onClick={() => changeAppointment()}
              disabled={loading}
            >
              Change
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};
export default ChangeAppointment;
